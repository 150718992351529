<template>
  <div class="flex items-center justify-center mt-10">
    <a-spin :tip="$t('common.Loading')"> </a-spin>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
export default defineComponent({
  name: 'Loading',
})
</script>