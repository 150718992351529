import mutations from './mutations';
import ConstantAPI, { EMS_SEVICE } from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { notification } from 'ant-design-vue';
import { checkResponse } from '@/util/common-utils';
import { i18n } from '@/main';

const state = () => ({
  loading: false,
  api: ConstantAPI.ALERT,
  detailAlert: null,
  action: null,
  listIOInGroup: [],
  listSelected: [],
  totalIOInGroup: 0,
});

function removeDuplicates(arr) {
  let seen = {};
  return arr.filter(item => {
    if (seen[item.id]) {
      return false;
    } else {
      seen[item.id] = true;
      return true;
    }
  });
}
const actions = {
  changeAction({ state }, action) {
    state.action = action;
  },
  resetData({ state }) {
    state.detailAlert = null;
    state.listIOInGroup = [];
    state.listSelected = []
  },

  async getDetail({ commit, state }, id) {
    commit('setLoading', true);
    const response = await DataService.callApi({
      url: state.api.GET_BY_ID.url + id,
      method: state.api.GET_BY_ID.method,
    });
    checkResponse(
      response,
      () => {
        state.detailAlert = response.data;
      },
      () => {
        state.detailAlert = null;
      }
    );
  },
  async create({ commit, state }, payload) {
    try {
      const { t } = i18n.global;
      commit('setLoading', true);
      const response = await DataService.callApi(state.api.CREATE, payload);
      let success = false;
      checkResponse(
        response,
        () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('alert_configuration.create_success'),
            duration: 4,
          });
        },
        () => {
          success = false;
          notification.error({
            message: t('common.notification'),
            description: t('alert_configuration.create_fail'),
            duration: 4,
          });
        }
      );
      return !success ? success : response.data;
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async preView({ commit, state }, row) {
    try {
      state.loading = true;
      const response = await DataService.get(`${EMS_SEVICE}/groups/${row.id}`);
      const responseIO = await DataService.get(
        `${EMS_SEVICE}/groups/${row.id}/io`
      );
      commit('setFormValue', {
        group: { ...response.data, children: row.children },
        ioGroup: responseIO.data,
      });
    } catch (e) {
      state.loading = false;
    }
  },
  async update({ commit, state }, payload) {
    try {
      const { t } = i18n.global;
      commit('setLoading', true);
      const response = await DataService.callApi(state.api.UPDATE, payload);
      let success = false;
      checkResponse(response, () => {
        success = true;
        notification.success({
          message: t('common.notification'),
          description: t('alert_configuration.update_success'),
          duration: 4,
        });
      });
      return success;
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async delete({ state }, rows) {
    try {
      const { t } = i18n.global;
      const response = await DataService.delete(
        state.api.DELETE.url + rows.map((e) => e.id).join(',')
      );
      let success = false;
      checkResponse(response, () => {
        success = true;
        notification.success({
          message: t('common.notification'),
          description: t('alert_configuration.delete_success'),
          duration: 4,
        });
      });
      return success;
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async getListIO({ state }, data) {
    try {
      state.loading = true;
      const response = await DataService.get(
        state.api.GET_LIST_ALL_IO.url,
        data
      );
      if (response.data.content) {
        const mapVal = response.data.content.map((e) => ({
          ...e,
          name: e.name ? e.name : e.id,
        }));
        state.listIOInGroup =
          data.page === 0 ? mapVal : state.listIOInGroup.concat(mapVal);
        state.listIOInGroup = [...state.listSelected, ...state.listIOInGroup];
        state.listIOInGroup = removeDuplicates(state.listIOInGroup)
      } else {
        state.listIOInGroup = data.page === 0 ? [] : state.listIOInGroup;
      }
      state.totalIOInGroup = response.data.totalElements;
      state.loading = false;
    } catch (err) {
      console.log(err);
    }
  },
  async getListProfileByIds({ state }, list) {
    try {
      state.loading = true;
      const response = await DataService.get(
        state.api.GET_LIST_BY_IDS.url + '?ids=' + list.join(',')
      );
      let listVal
      checkResponse(response, () => {
        const mapVal = response.data.map((e) => ({
          ...e,
          name: e.name ? e.name : e.id,
        }));
        state.listSelected = [...mapVal, ...state.listSelected];
        state.listIOInGroup = [...state.listSelected, ...state.listIOInGroup];
        state.listIOInGroup = removeDuplicates(state.listIOInGroup)
        listVal = response.data
      });
      return listVal;
    } catch (err) {
      console.log(err);
    }
  },
  setSelectedList({ state }, list) {
    state.listSelected = list
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
