import axios from 'axios';
import { checkResponse, formatDate } from '@/util/common-utils';
import { notification } from 'ant-design-vue';
import store from '@/vuex/store';
import ConstantAPI from '@/config/ConstantAPI';
import router from '@/routes/protectedRoute';
import { i18n } from '@/main';

const API_ENDPOINT = window.VUE_APP_API_ENDPOINT;
const USERNAME = window.VUE_APP_AUTH_CLIENT;
const PASSWORD = window.VUE_APP_AUTH_SECRET;
const BASIC_AUTH = `Basic ${btoa(USERNAME + ':' + PASSWORD)}`;

const httpClient = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': localStorage.getItem('locale')
      ? localStorage.getItem('locale')
      : 'vi',
  },
  withCredentials: false,
});

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
httpClient.interceptors.request.use((config) => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const { headers } = config;
  if (/[\w]*\/oauth\/[\w]*/g.test(config.url)) {
    config.headers = {
      ...headers,
      Authorization: BASIC_AUTH,
    };
  } else {
    config.headers = {
      ...headers,
      Authorization: `Bearer ${store.getters.accessToken}`,
    };
  }
  Object.keys(config.params || {}).forEach((k) => {
    const date = config.params[k];
    if (date instanceof Date) {
      config.params[k] = formatDate(date, 'YYYY-MM-DD HH:mm:ss');
    }
  });
  if (config.params && Object.keys(config.params).length > 0) {
    let params = new URLSearchParams();
    for (let key in config.params) {
      if (Array.isArray(config.params[key])) {
        for (let data of config.params[key]) {
          if (data) {
            params.append(key, data);
          }
        }
      } else {
        if (config.params[key]) {
          params.append(key, config.params[key]);
        }
      }
    }
    config.params = params;
  }
  return config;
});

let refreshing = false;

httpClient.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    const config = error.config;
    const { t } = i18n.global;
    if (/[\w]*\/oauth\/[\w*]/g.test(config.url) || config.retry) {
      return Promise.reject(error.response.data);
    }
    if (error.response) {
      let errorMessage =
        error.response.data === null
          ? t('common.internal_error')
          : error.response.data.message;
      switch (error.response.status) {
        case 404:
          notification.error({
            message: t('common.notification'),
            description: t('common.not_found_error'),
            duration: 4,
          });
          break;
        case 403:
          notification.error({
            message: t('common.notification'),
            description: t('common.not_access_error'),
            duration: 4,
          });
          break;
        case 401:
          try {
            if (refreshing && !config.retry) {
              for (let i = 0; i < 10; i++) {
                await new Promise((r) => setTimeout(r, 100));
                if (!refreshing) break;
              }
              return httpClient(config);
            }
            config.retry = true;
            refreshing = true;
            const response = await DataService.callApi(
              ConstantAPI.auth.REFRESH_TOKEN,
              null,
              { refresh_token: store.getters.refreshToken }
            );
            checkResponse(
              response,
              async () => {
                store.commit('setTokenInfo', response.data);
                const url = window.location.pathname;
                if (url.includes('auth')) {
                  router.push('/');
                }
              },
              async () => {
                await store.dispatch('logOut');
                refreshing = false;
                return Promise.reject(response);
              }
            );
            refreshing = false;
            return httpClient(config);
          } catch (_error) {
            await store.dispatch('logOut');
            refreshing = false;
            return Promise.reject(_error);
          }
        default:
          notification.error({
            message: t('common.notification'),
            description: errorMessage,
            duration: 4,
          });
          break;
      }
    }
    return Promise.reject(
      error.response ? error.response.data : error.response
    );
  }
);

class DataService {
  static callApi(api, data, params, headers) {
    return httpClient({
      method: api.method,
      url: api.url,
      data,
      params,
      headers,
    });
  }

  static upload(api, data, config) {
    return httpClient({
      method: api.method,
      url: api.url,
      data,
      ...config,
    });
  }

  static getConfig(path = '', params, headers, responseType) {
    return httpClient.get(path, { headers, params, responseType });
  }

  static get(path = '', params, headers) {
    return httpClient.get(path, { headers, params });
  }

  static post(path = '', data = {}, headers) {
    return httpClient.post(path, data, { headers });
  }

  static patch(path = '', data = {}, headers) {
    return httpClient.patch(path, data, { headers });
  }

  static delete(path = '', data = {}, headers) {
    return httpClient.delete(path, data, { headers });
  }

  static put(path = '', data = {}, headers) {
    return httpClient.put(path, data, { headers });
  }
}

export { DataService };
