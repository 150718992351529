export default {
  fetchDataBegin(state) {
    state.loading = true;
  },
  fetchDataSuccess(state, data) {
    state.loading = false;
    state.locations = data;
  },
  fetchDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },
};