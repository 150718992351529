import { FORM_MODE } from '@/util/common-constant';

export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  getAllSuccess(state, data) {
    state.list = data;
    state.loading = false;
  },
  preCreate(state) {
    state.visible = true;
    state.formMode = FORM_MODE.CREATE;
    state.user = {
      username: '',
      description: '',
      phone: '',
      email: '',
      userRoles: [],
      gender: 1,
      status: 1,
      deptId: null,
    };
  },
  setFormValue(state, { user, formMode }) {
    state.visible = true;
    state.formMode = formMode;
    state.user = {
      ...user,
    };
  },
  setSuggest(state, {organizations, departments, positions}) {
    state.organizations = (organizations || []).map(e => ({value: e, text: e}));
    state.departments = (departments || []).map(e => ({value: e, text: e}));
    state.positions = (positions || []).map(e => ({value: e, text: e}));
  },
};
