export default {
  fetchDataBegin(state) {
    state.loading = true;
  },
  fetchDataSuccess(state, data) {
    state.loading = false;
    state.person = data;
  },
  fetchDataCardTypesSuccess(state, data) {
    state.cardTypes = data;
  },
  fetchDataSuggestPositionSuccess(state, data) {
    state.suggestPositions = data;
  },
  fetchDataSuggestDepartmentSuccess(state, data) {
    state.suggestDepartments = data;
  },
  fetchDataSuggestOrganizationSuccess(state, data) {
    state.suggestOrganizations = data;
  },
  fetchDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },
};