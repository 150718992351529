import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { checkResponse } from '@/util/common-utils';
import { notification } from 'ant-design-vue';
import { showConfirm } from '@/util/confirm';
import { i18n } from '@/main';

const state = () => ({
  loading: false,
  device: [],
  users: [],
  devices: [],
  api: ConstantAPI.DEVICE,
  visible: false,
  action: 0,
  listDeviceStatus: [],
});

const actions = {
  changeAction({ state }, action) {
    state.action = action;
  },
  async getAll({ state }, params) {
    try {
      const response = await DataService.callApi(
        state.api.GET_ALL,
        null,
        params
      );
      state.devices = response.data;
    } catch (err) {
      console.log(err);
    }
  },
  async preCreate({ commit }) {
    commit('preCreate');
  },
  async preUpdate({ state, commit }, row) {
    // const response = await DataService.callApi(
    //   ConstantAPI.user_role.GET_BY_USER,
    //   null,
    //   { userId: row.id }
    // );
    state.action = 1;
    commit('setFormValue', row);
  },
  async preView({ state, commit }, row) {
    // const response = await DataService.callApi(
    //   ConstantAPI.user_role.GET_BY_USER,
    //   null,
    //   { userId: row.id }
    // );
    state.action = 0;
    commit('setFormValue', row);
  },
  resetForm({ state }) {
    state.group = null;
  },
  async create({ commit, state }, payload) {
    try {
      const { t } = i18n.global;
      if (await showConfirm(t('common.confirm_save'))) {
        commit('setLoading', true);
        const response = await DataService.callApi(state.api.CREATE, {
          ...payload,
          type: 2,
          children: (payload.children || []).map((e) => ({ id: e })),
          userGroup: (payload.userGroup || []).map((e) => ({ userId: e })),
        });
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('common.create_success', {
              name: t('user_group.u_group_tree'),
            }),
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async update({ commit, state }, payload) {
    try {
      const { t } = i18n.global;
      if (await showConfirm(t('common.confirm_save'))) {
        commit('setLoading', true);
        const response = await DataService.callApi(state.api.UPDATE, {
          ...payload,
          type: 2,
          children: (payload.children || []).map((e) => ({ id: e })),
          userGroup: (payload.userGroup || []).map((e) => ({ userId: e })),
        });
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('common.update_success', {
              name: t('user_group.u_group_tree'),
            }),
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async delete({ state }, { id }) {
    try {
      const { t } = i18n.global;
      if (await showConfirm(t('common.confirm_delete'))) {
        const response = await DataService.delete(
          state.api.DELETE.url + '/' + id
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('common.delete_success', {
              name: t('user_group.u_group_tree'),
            }),
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async getStatusAllDevice({ state }) {
    try {
      const response = await DataService.callApi(state.api.GET_ALL, null);
      state.devices = response.data;
      let devices = {};
      for (let id in state.devices) {
        devices[state.devices[id].userId] = state.devices[id];
      }
      const response1 = await DataService.get(
        ConstantAPI.STATUS.GET_LIST_STATUS.url +
          `/${Object.keys(devices).join(',')}`
      );
      checkResponse(response1, () => {
        for (let key in response1.data) {
          devices[response1.data[key].userId] = {
            ...devices[response1.data[key].userId],
            online: response1.data[key].online,
            lastOnline: response1.data[key].lastOnline,
            locationDetail: response1.data[key].location,
          };
        }
        state.listDeviceStatus = Object.values(devices);
      });
    } catch (err) {
      console.log(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
