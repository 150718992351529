import mutations from './mutations';
import { getField, updateField } from 'vuex-map-fields';
import { DataService } from '@/dataService/dataService';
import { checkResponse } from '@/util/common-utils';
import ConstantAPI from '@/config/ConstantAPI';
const state = () => ({
  listCamera:[],
  api: ConstantAPI.CAMERA,
  groupCamera:[]
});

const actions = {
  async getAllListCamera({state}){
    try {
      const response = await DataService.callApi(
        state.api.GET_LIST_CAMERA_INFO,
      );
      checkResponse(response, () => {
        state.listCamera = response.data.content? response.data.content : [];
      });
    } catch (err) {
      console.log(err);
    }
  },
  async getGroupCamera({state}){
    state.groupCamera =[]
    try {
      const response = await DataService.callApi(
        state.api.GET_LIST_GROUP_CAMERA,
      );
      checkResponse(response, () => {
        response.data.map((item)=>{
          state.groupCamera.push({
            label: item.name,
            value: item.id
          })
        })
      });
    } catch (err) {
      console.log(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
