import {flatten} from "@/util/common-utils";

export default {
    setLoading(state, loading) {
        state.loading = loading;
    },
    getAllSuccess(state, data) {
        state.tree = data;
        state.groups = flatten(data)
        state.loading = false;
    },
    preCreate(state) {
        state.group = {
            name: '',
            parentId: null,
            children: [],
            userGroup: [],
            description: '',
        };
    },
    setFormValue(state, {group, userGroup}) {
        state.visible = true;
        state.group = {
            ...group,
            children: (group.children || []).map(e => e.id),
            userGroup: (userGroup || []).map(e => e.id),
            permissions: []
        };
        state.users = userGroup
        state.loading = false
    },
    setPermission(state, {permissions}) {
        state.group.permissions = permissions.map(permission => {
            const p = {
                ...permission,
                io: permission.create || permission.view || permission.update || permission.delete,
                ios: (permission.ios || []).reduce((obj, arr) => {
                    return {...obj, [arr]: true}
                }, {}),
            }
            if (permission.dateFrom) {
                p.time = [permission.dateFrom, permission.dateTo]
            }
            return p
        })
    }
};
