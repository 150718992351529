<template>
  <p v-if="readOnly" class="text-white">{{ getText() }}</p>
  <a-tree-select
    v-else
    v-model:value="valueSelect"
    :show-search="showSearch"
    style="width: 100%"
    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
    :placeholder="placeholder"
    allow-clear
    :treeCheckStrictly="checkStrictly"
    :tree-data="treeData"
    :fieldNames="fieldNames"
    :size="size"
    :maxTagCount="maxTagCount"
    @change="change"
    class="cds-tree-select"
  >
  </a-tree-select>
</template>

<script>
import { computed, defineComponent, ref, watch } from 'vue';
import VueTypes from 'vue-types';
import _ from 'lodash';

export default defineComponent({
  name: 'TreeSelect',
  props: {
    treeData: VueTypes.array.def([]),
    value: VueTypes.oneOfType([
      VueTypes.array,
      VueTypes.string,
      VueTypes.number,
    ]).def(),
    checkStrictly: VueTypes.bool.def(false),
    fieldNames: VueTypes.object,
    placeholder: VueTypes.string.def(''),
    readOnly: VueTypes.bool.def(false),
    size: VueTypes.string.def('small'),
    maxTagCount: VueTypes.number.def(1),
    showSearch:VueTypes.bool.def(true)
  },
  setup(props, { emit }) {
    const valueSelect = ref(props.value);
    const treeData = ref(props.treeData);
    const fieldNames = ref(props.fieldNames);
    watch(
      () => props.value,
      (val) => (valueSelect.value = val)
    );
    const change = (val) => emit('update:value', val);
    const getText = computed(() => () => {
      const flattenData = treeData.value.reduce((newArr, arr) => {
        const { children, ...rest } = arr;
        newArr.push(rest);
        return newArr.concat(children);
      }, []);
      const parent = flattenData.find(
        (e) =>
          e &&
          !_.isNil(valueSelect.value) &&
          e[fieldNames.value.value] == valueSelect.value
      );
      if (parent) return parent[fieldNames.value.label];
    });
    return {
      valueSelect,
      change,
      getText,
    };
  },
});
</script>

<style lang="scss">
.ant-select-tree-list {
  background: rgba(31, 31, 31, var(--tw-bg-opacity));
  color: white;
}
.ant-select-tree-list .ant-select-tree-node-content-wrapper:hover {
  background-color: #252627 !important;
}
.ant-select-selector {
  height: 39px !important;
  border-radius: unset !important;
  background-color: #434343 !important;
}
.ant-select-clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: white;
  font-size: 12px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  background: transparent;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
}
.ant-select-tree-treenode-disabled .ant-select-tree-title {
  --tw-text-opacity: 1;
  color: rgba(233, 233, 233, var(--tw-text-opacity));
}
</style>
