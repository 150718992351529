export default {
  fetchDataBegin(state) {
    state.loading = true;
  },
  setInitData(state, data) {
    let listContactsLike = [];
    let listContactsUnLike = [];
    data.forEach((item) => {
      if (item.like) {
        listContactsLike.push(item);
      } else {
        listContactsUnLike.push(item);
      }
    });
    state.listContacts = [
      {
        value: 'coordinator.like',
        listData: listContactsLike,
        icon: require('@/static/img/icon/favourite.svg'),
      },
      {
        value: 'coordinator.list',
        listData: listContactsUnLike,
        icon: require('@/static/img/icon/ClockCountdownRed.svg'),
      },
    ];
  },
  setInitDataChannels(state, data) {
    let listChannelLike = [];
    let listChannelUnLike = [];
    data.forEach((item) => {
      if (item.liked) {
        listChannelLike.push(item);
      } else {
        listChannelUnLike.push(item);
      }
    });
    state.listChannels = [
      {
        value: 'coordinator.like',
        listData: listChannelLike,
        icon: require('@/static/img/icon/favourite.svg'),
      },
      {
        value: 'coordinator.list',
        listData: listChannelUnLike,
        icon: require('@/static/img/icon/ClockCountdownRed.svg'),
      },
    ];
  },
  SET_CLIENT(state, client) {
    state.client = client;
  },
  SET_LOCAL_TRACKS(state, tracks) {
    state.localTracks = tracks;
  },
  SET_IN_CHANNEL(state, inChannel) {
    state.inChannel = inChannel;
  },
  SET_TALKING(state, talking) {
    state.talking = talking;
  },
  ADD_REMOTE_USER(state, user) {
    state.remoteUsers = {
      ...state.remoteUsers,
      [user.uid]: user,
    };
  },
  SET_REMOTE_USER(state, uid) {
    // eslint-disable-next-line no-unused-vars
    const { [uid]: _, ...remainingUsers } = state.remoteUsers;
    state.remoteUsers = remainingUsers;
  },
};
