import { Modal } from 'ant-design-vue';
import {i18n} from "@/main";

export function showConfirm(message, okText, cancelText) {
  const {t} = i18n.global
  return new Promise((resolve) => {
    Modal.confirm({
      title: t('common.confirm'),
      content: message,
      okText: okText || t('common.yes'),
      cancelText: cancelText || t('common.no'),
      onOk: () => resolve(true),
      onCancel: () => resolve(false),
    });
  });
}
