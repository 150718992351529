import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import {DataService} from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';

const state = () => ({
  locations: [],
  loading: false,
  error: null,
});
const actions = {
  async getAllLocations({ commit }) {
    try {
      commit('fetchDataBegin');
      const response = await DataService.get(`${ConstantAPI.indentity_management.GET_ALL_LOCATION.url}`);
      return commit('fetchDataSuccess', response.data);
    } catch (err) {
      commit('fetchDataErr', err);
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
      ...mutations,
      updateField,
  },
  getters: {
      getField,
  },
};

