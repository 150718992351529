import { flatten } from '@/util/common-utils';

export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  getAllSuccess(state, data) {
    state.tree = data;
    state.devices = flatten(data);
    state.loading = false;
  },
  preCreate(state) {
    state.device = {
      name: null,
      code: null,
      type: null,
      user: null,
    };
    state.action = 2;
  },
  setFormValue(state, { device }) {
    state.visible = true;
    state.device = device;
    state.loading = false;
  },
};
