export default {
  fetchDataBegin(state) {
    state.loading = true;
  },
  fetchDataSuccess(state) {
    state.loading = false;
  },
  fetchDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },
};
