<template>
  <a-empty
    :image="simpleImage"
    :description="description ? description : t('common.empty-table')"
  />
</template>
<script setup>
import { Empty } from 'ant-design-vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
defineProps({
  description: { type: String, default: '' },
});
const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
</script>
