import {DataService} from '@/dataService/dataService';
import {getField, updateField} from 'vuex-map-fields';
import {notification} from 'ant-design-vue';
import {checkResponse} from '@/util/common-utils';
import {i18n} from '@/main';
import ConstantAPI from "@/config/ConstantAPI";

const state = () => ({
  loading: false,
});

const actions = {
  async deleteIdentityObj({ state }, rows) {
    const { t } = i18n.global;
    try {
        state.loading = true
        const response = await DataService.delete(
            ConstantAPI.OBJECT_MANAGEMENT.DELETE.url + rows.id
        );
        let success = false;
        checkResponse(
            response,
            () => {
              success = true;
              notification.success({
                message: t('common.notification'),
                description: t('object_information.delete_obj_success'),
                duration: 4,
              });
            },
            () => {
              success = false;
              notification.error({
                message: t('common.notification'),
                description: t('object_information.delete_obj_fail'),
                duration: 4,
              });
            }
        );
        return success;
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      state.loading = false
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    updateField,
  },
  getters: {
    getField,
  },
};
