import mutations from './mutations';
import ConstantAPI, {EMS_SEVICE} from '@/config/ConstantAPI';
import {DataService} from '@/dataService/dataService';
import {getField, updateField} from 'vuex-map-fields';
import {FORM_MODE} from '@/util/common-constant';
import {checkResponse} from '@/util/common-utils';
import {notification} from 'ant-design-vue';
import {showConfirm} from '@/util/confirm';
import {i18n} from "@/main";

const state = () => ({
    tree: [],
    loading: false,
    group: null,
    users: [],
    groups: [],
    api: ConstantAPI.user_group,
    visible: false,
});

const actions = {
    async getAll({commit, state}) {
        try {
            const response = await DataService.callApi(state.api.SEARCH, null, {type: 2});
            return commit('getAllSuccess', response.data);
        } catch (err) {
            console.log(err);
        }
    },
    async preCreate({commit}) {
        commit('preCreate');
    },
    async preUpdate({commit}, row) {
        const response = await DataService.callApi(
            ConstantAPI.user_role.GET_BY_USER,
            null,
            {userId: row.id}
        );
        commit('setFormValue', {
            user: {...row, userRoles: (response.data || []).map((e) => e.roleId)},
            formMode: FORM_MODE.UPDATE,
        });
    },
    async preView({commit, state}, row) {
        try {
            state.loading = true
            const response = await DataService.get(
                `${EMS_SEVICE}/groups/${row.id}`,
            );
            const responseUsers = await DataService.get(
                `${EMS_SEVICE}/groups/${row.id}/user`,
            );
            commit('setFormValue', {group: {...response.data, children: row.children}, userGroup: responseUsers.data});
        } catch (e) {
            state.loading = false
        }
    },
    async getPermission({commit, state}, row) {
        try {
            const response = await DataService.get(
                `${state.api.PERMISSION.url}/${row.id}`,
            );
            commit('setPermission', {permissions: response.data});
        } catch (e) {
            state.loading = false
            console.error(e)
        }
    },
    resetForm({state}) {
        state.group = null
    },
    async create({commit, state}, payload) {
        try {
            const {t} = i18n.global
            if (await showConfirm(t('common.confirm_save'))) {
                commit('setLoading', true);
                const response = await DataService.callApi(
                    state.api.CREATE,
                    {
                        ...payload,
                        type: 2,
                        children: (payload.children || []).map(e => ({id: e})),
                        userGroup: (payload.userGroup || []).map(e => ({userId: e}))
                    },
                );
                let success = false;
                checkResponse(response, () => {
                    success = true;
                    notification.success({
                        message: t('common.notification'),
                        description: t('common.create_success', {name: t('user_group.u_group_tree')}),
                        duration: 4,
                    });
                });
                return success;
            }
        } catch (err) {
            console.log(err);
            return false;
        } finally {
            commit('setLoading', false);
        }
    },
    async update({commit, state}, payload) {
        try {
            const {t} = i18n.global
            if (await showConfirm(t('common.confirm_save'))) {
                commit('setLoading', true);
                const response = await DataService.callApi(
                    state.api.UPDATE,
                    {
                        ...payload,
                        type: 2,
                        children: (payload.children || []).map(e => ({id: e})),
                        userGroup: (payload.userGroup || []).map(e => ({userId: e}))
                    },
                );
                let success = false;
                checkResponse(response, () => {
                    success = true;
                    notification.success({
                        message: t('common.notification'),
                        description: t('common.update_success', {name: t('user_group.u_group_tree')}),
                        duration: 4,
                    });
                });
                return success;
            }
        } catch (err) {
            console.log(err);
            return false;
        } finally {
            commit('setLoading', false);
        }
    },
    async updatePermission({commit, state}) {
        try {
            const {t} = i18n.global
            if (await showConfirm(t('common.confirm_save'))) {
                commit('setLoading', true);
                if (!state.group.id) return
                const response = await DataService.post(
                    `${state.api.PERMISSION.url}/${state.group.id}`,
                    state.group.permissions.map(e => {
                        const p = {
                            ...e,
                            ios: Object.keys(e.ios).filter(id => e.ios[id]).map(id => id),
                        }

                        if (p.time && p.time.length > 0) {
                            p.dateFrom = p.time[0]
                            p.dateTo = p.time[1]
                        }
                        return p;
                    },
                ));
                let success = false;
                checkResponse(response, () => {
                    success = true;
                    notification.success({
                        message: t('common.notification'),
                        description: t('common.update_success', {name: t('user_group.u_group_tree')}),
                        duration: 4,
                    });
                });
                return success;
            }
        } catch (err) {
            console.log(err);
            return false;
        } finally {
            commit('setLoading', false);
        }
    },
    async delete({state}, {id}) {
        try {
            const {t} = i18n.global
            if (await showConfirm(t('common.confirm_delete'))) {
                const response = await DataService.delete(
                    state.api.DELETE.url + '/' + id
                );
                let success = false;
                checkResponse(response, () => {
                    success = true;
                    notification.success({
                        message: t('common.notification'),
                        description: t('common.delete_success', {name: t('user_group.u_group_tree')}),
                        duration: 4,
                    });
                });
                return success;
            }
        } catch (err) {
            console.log(err);
            return false;
        }
    },
    async setVisible({state}, visible) {
        state.visible = visible;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations: {
        ...mutations,
        updateField,
    },
    getters: {
        getField,
    },
};
