<template>
  <a-config-provider :locale="locale" class="h-full">
    <img src="require('/src/static/img/Logo_Dark.png')" alt="" />
    <div v-if="isLoading" class="spin">
      <a-spin />
    </div>
    <ThemeProvider
      v-else
      :theme="{
        ...theme,
        rtl,
        topMenu,
        darkMode,
      }"
      class="h-full"
    >
      <Suspense>
        <template #default>
          <router-view></router-view>
        </template>
        <template #fallback>
          <div class="spin">
            <a-spin />
          </div>
        </template>
      </Suspense>
    </ThemeProvider>
  </a-config-provider>
</template>
<script>
import { ThemeProvider } from 'vue3-styled-components';
import { theme } from './config/theme/themeVariables';
import { computed, defineComponent, onMounted } from 'vue';
import { useStore } from 'vuex';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import 'dayjs/locale/en';
import 'dayjs/locale/ru';
import vi_VN from 'ant-design-vue/es/locale/vi_VN';
import en_US from 'ant-design-vue/es/locale/en_US';
import ru_RU from 'ant-design-vue/es/locale/ru_RU';



export default defineComponent({
  name: 'App',
  components: {
    ThemeProvider,
  },
  setup() {
    const languageCode = localStorage.getItem('locale');
    dayjs.locale(languageCode);
    const { state, dispatch } = useStore();
    const rtl = computed(() => state.themeLayout.rtlData);
    const isLoading = computed(() => state.themeLayout.loading);
    const darkMode = computed(() => state.themeLayout.data);
    const topMenu = computed(() => state.themeLayout.topMenu);
    const isFbAuthLoading = computed(() => state.firebase.loading);
    const locale = computed(() => {
      switch (languageCode) {
        case 'ru':
          return ru_RU;
        case 'en':
          return en_US;
        default:
          return vi_VN;
      }
    });

    onMounted(() => {
      dispatch('getUserInfo');
    });

    return {
      theme,
      rtl,
      darkMode,
      topMenu,
      isLoading,
      isFbAuthLoading,
      locale,
    };
  },
});
</script>
