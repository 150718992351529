import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { notification } from 'ant-design-vue';
import { checkResponse } from '@/util/common-utils';
import { i18n } from '@/main';

const state = () => ({
  listProfile: [],
  listEvent: [],
  totalEvent: 0,
  listProfileToMove: [],
});
const actions = {
  async getListProfile({ state }, { keyword, type, groupIds, page, reset }) {
    const { t } = i18n.global;
    try {
      const response = await DataService.callApi(
        ConstantAPI.EVENT_VERIFY.GET_LIST_PROFILE,
        null,
        {
          keyword,
          type,
          groupIds,
          page,
        }
      );

      let success = false;
      if (reset) {
        state.listProfile = [];
      }
      checkResponse(
        response,
        () => {
          success = true;
          state.listProfile = response.data
            ? state.listProfile.concat(response.data)
            : state.listProfile;
          if (state.listProfile.length < 1) {
            state.listEvent = [];
          }
        },
        () => {
          success = false;
          notification.error({
            message: t('common.notification'),
            description: '',
            duration: 4,
          });
        }
      );
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  async getListEventVerify(
    { state },
    { ioId, percentGte, percentLte, dateFrom, dateTo, page, reset }
  ) {
    const { t } = i18n.global;
    try {
      const response = await DataService.callApi(
        ConstantAPI.EVENT_VERIFY.GET_LIST_EVENT,
        null,
        {
          ioId,
          percentGte,
          percentLte,
          dateFrom,
          dateTo,
          page,
          size: 20,
          reset,
        }
      );

      let success = false;
      if (reset) {
        state.listEvent = [];
      }
      checkResponse(
        response,
        () => {
          success = true;
          state.listEvent = response.data.content
            ? state.listEvent.concat(response.data.content)
            : state.listEvent;
          state.totalEvent = response.data.totalElements;
        },
        () => {
          success = false;
          notification.error({
            message: t('common.notification'),
            description: '',
            duration: 4,
          });
        }
      );
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  async mergeEvent({ commit }, { id, idsSimilar }) {
    try {
      const response = await DataService.callApi(
        ConstantAPI.EVENT_VERIFY.MERGE_EVENT,
        { id, idsSimilar }
      );
      let success = false;
      checkResponse(
        response,
        () => {
          success = true;
        },
        () => {
          success = false;
        }
      );
      commit('setLoading', false);
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  async delete({ state }, ids) {
    const { t } = i18n.global;
    try {
      state.loading = true;
      const response = await DataService.delete(
        ConstantAPI.IDENTIFICATION_EVENT.DELETE.url + ids.join(',')
      );
      let success = false;
      checkResponse(
        response,
        () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('identification-event.delete_event_success'),
            duration: 4,
          });
        },
        () => {
          success = false;
          notification.error({
            message: t('common.notification'),
            description: t('identification-event.delete_event_fail'),
            duration: 4,
          });
        }
      );
      return success;
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      state.loading = false;
    }
  },
  async getListProfileToMove({ state }, { keyword, page = 0, reset }) {
    const { t } = i18n.global;
    try {
      const response = await DataService.callApi(
          ConstantAPI.EVENT_VERIFY.GET_LIST_PROFILE,
          null,
          {
            keyword,
            type: 1, // Tạm chỉ lấy hồ sơ là người
            page,
            size: 6
          }
      );

      let success = false;
      if (reset) {
        state.listProfileToMove = [];
      }
      checkResponse(
          response,
          () => {
            success = true;
            state.listProfileToMove = response.data
                ? state.listProfileToMove.concat(response.data)
                : state.listProfileToMove;
          },
          () => {
            success = false;
            notification.error({
              message: t('common.notification'),
              description: '',
              duration: 4,
            });
          }
      );
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  resetForm({ state }) {
    state.listProfile = [];
    state.listEvent = [];
    state.totalEvent = 0;
    state.listProfileToMove = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
