import { createRouter, createWebHistory } from 'vue-router';
import authRoutes from './authRoutes';
import adminRoutes from "@/routes/adminRoutes";

const routes = [
  {
    name: 'Admin',
    path: '/',
    component: () =>
      import(/* webpackChunkName: "admin" */ '../layout/withAdminLayout.vue'),
    children: adminRoutes,
    meta: { auth: false },
  },
  {
    name: 'Auth',
    path: '/auth',
    component: () =>
      import(/* webpackChunkName: "auth" */ '@/layout/withAuthLayout.vue'),
    children: [...authRoutes],
    meta: { auth: true },
  },
  {
    path: '/:catchAll(.*)',
    component: () =>
      import(/* webpackChunkName: "404" */ '@/view/pages/404.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkExactActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    if(savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
  routes,
});
//
// router.beforeEach((to, from, next) => {
//   if (to.meta.auth && store.getters.accessToken) {
//     next({ to: '/' });
//   } else if (!to.meta.auth && !store.getters.accessToken) {
//     next({ name: 'login' });
//   } else {
//     if (!router.getRoutes().some((r) => r.path === to.path)) {
//       if (store.getters.routerLoaded) {
//         next();
//       } else {
//         (store.getters.menus || [])
//           .filter((c) => c.component !== 'HomePageView')
//           .forEach((c) => router.addRoute('Admin', convertToRouter(c)));
//         next({ ...to, replace: true });
//         store.commit('routerLoaded');
//       }
//     } else {
//       next();
//     }
//   }
// });
//
// const convertToRouter = (menu) => {
//   const path = menu.component;
//   if (!_.isEmpty(menu.component)) {
//     menu.component = () =>
//       import(/* webpackChunkName: "dynamic component" */ `@/${path}`);
//   }
//   if (menu.hasChildren) {
//     menu.children = menu.children.map((c) => convertToRouter(c));
//   }
//   return menu;
// };

export default router;
