<template>
  <div class="flex items-center space-x-[16px] pb-[16px]">
    <ClipButton
      v-if="permissions.hasReset"
      type="reset"
      size="small"
      style="height: fit-content"
      class="w-max h-[40px]"
      @click="emitEvent('onClear')"
    >
      <cds-feather-icons
        type="rotate-cw"
        size="16"
        class="text-ems-white mr-2"
      />
      <span class="text-ems-white">{{ $t('common.reset') }}</span>
    </ClipButton>

    <ClipButton
      v-if="permissions.hasSearch"
      type="primary"
      size="small"
      style="height: fit-content"
      class="w-max"
      @click="emitEvent('onSearch')"
    >
      <cds-feather-icons type="search" size="16" class="text-ems-white mr-2" />
      <span class="text-ems-white">{{ $t('common.search') }}</span>
    </ClipButton>

    <ClipButton
      v-if="permissions.hasCreate"
      type="primary"
      size="small"
      style="height: fit-content"
      class="w-max"
      @click="emitEvent('onCreate')"
    >
      <cds-feather-icons type="plus" size="16" class="text-ems-white mr-2" />
      <span class="text-ems-white">{{ $t('common.add') }}</span>
    </ClipButton>

    <ClipButton
      v-if="permissions.hasCreateMenu"
      type="primary"
      size="small"
      style="height: fit-content"
      class="w-max"
      @click="emitEvent('onCreateMenu')"
    >
      <cds-feather-icons type="plus" size="18" class="text-ems-white mr-2" />
      <span class="text-ems-white">{{ $t('common.create-menu') }}</span>
    </ClipButton>

    <ClipButton
      v-if="permissions.hasCreateButton"
      type="primary"
      size="small"
      style="height: fit-content"
      class="w-max"
      @click="emitEvent('onCreateButton')"
    >
      <cds-feather-icons type="plus" size="18" class="text-ems-white mr-2" />
      <span class="text-ems-white">{{ $t('common.create-button') }}</span>
    </ClipButton>

    <ClipButton
      v-if="permissions.hasExportExcel"
      type="primary"
      size="small"
      style="height: fit-content"
      class="w-max"
      @click="emitEvent('onExportExcel')"
    >
      <cds-feather-icons
        type="download"
        size="16"
        class="text-ems-white mr-2"
      />
      <span class="text-ems-white"> {{ $t('common.export-excel') }}</span>
    </ClipButton>

    <ClipButton
      v-if="permissions.hasApproval"
      type="primary"
      size="small"
      style="height: fit-content"
      class="w-max"
      @click="emitEvent('onApproval')"
    >
      <cds-feather-icons type="check" size="16" class="text-ems-white mr-2" />
      <span class="text-ems-white"> {{ $t('common.approval') }}</span>
    </ClipButton>

    <ClipButton
      v-if="permissions.hasCancelApproval"
      type="primary"
      size="small"
      style="height: fit-content"
      class="w-max"
      @click="emitEvent('onCancelApproval')"
    >
      <cds-feather-icons type="x" size="16" class="text-ems-white mr-2" />
      <span class="text-ems-white">{{ $t('common.cancel_1') }}</span>
    </ClipButton>
    <ClipButton
      v-if="permissions.hasWarning"
      type="primary"
      class="text-ems-gray50 whitespace-nowrap flex gap-1 py-2 items-center !px-5"
      @click="emitEvent('onWarning')"
    >
      <img
        class="w-18px h-18px m-auto"
        src="@/static/img/icon/WarningIconWhite.svg"
        alt="WarningIconWhite"
      />
      {{ $t('object_tracing.warning') }}
    </ClipButton>
    <ClipButton
      v-if="permissions.hasDelete"
      type="primary"
      size="small"
      style="height: fit-content"
      class="w-max"
      @click="emitEvent('onDelete')"
    >
      <cds-feather-icons type="trash-2" size="18" class="text-ems-white mr-2" />
      <span class="text-ems-white">{{ $t('common.delete') }}</span>
    </ClipButton>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import { computed, defineComponent, watch, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import ClipButton from '@/components/buttons/ClipButton.vue';

export default defineComponent({
  name: 'SearchFormButton',
  components: {
    ClipButton,
  },
  props: {
    buttons: VueTypes.array.def([]),
  },
  setup(props, { emit }) {
    const emitEvent = (event) => {
      emit(event);
    };
    const { state } = useStore();
    const perms = computed(() => state.auth.userInfo.permissions);
    const hasButton = (button) =>
      (props.buttons || []).some(
        (e) =>
          e.type === button &&
          (!e.permission || (perms.value || []).includes(e.permission))
      );
    const permissions = ref({
      hasReset: false,
      hasSearch: false,
      hasCreate: false,
      hasCreateMenu: false,
      hasCreateButton: false,
      hasDelete: false,
      hasApproval: false,
      hasCancelApproval: false,
      hasExportExcel: false,
      hasWarning: false,
    });
    const fetchPerm = () => {
      permissions.value = {
        hasReset: hasButton('RESET'),
        hasSearch: hasButton('SEARCH'),
        hasCreate: hasButton('CREATE'),
        hasCreateMenu: hasButton('CREATE_MENU'),
        hasCreateButton: hasButton('CREATE_BUTTON'),
        hasDelete: hasButton('DELETE'),
        hasApproval: hasButton('APPROVAL'),
        hasCancelApproval: hasButton('CANCEL_APPROVAL'),
        hasExportExcel: hasButton('EXPORT_EXCEL'),
        hasWarning: hasButton('WARNING'),
      };
    };
    watch(() => perms.value, fetchPerm);
    onMounted(fetchPerm);
    return {
      emitEvent,
      permissions,
    };
  },
});
</script>

<style scoped>

</style>
