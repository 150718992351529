<template>
  <DatePickerWrapper>
    <a-range-picker
      v-model:value="valueDate"
      @change="change"
      :disabled-date="disabledDate"
      :placeholder="['Từ ngày', 'Đến ngày']"
      :format="format"
      class="w-100"
      :show-time="showTime"
      size="small"
    >
    </a-range-picker>
  </DatePickerWrapper>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import { DatePickerWrapper } from './style';
import VueTypes from 'vue-types';
import dayjs from 'dayjs';

export default defineComponent({
  name: 'DateRangePicker',
  components: {
    DatePickerWrapper,
  },
  props: {
    value: VueTypes.array.def([]),
    format: VueTypes.string.def('DD/MM/YYYY'),
    disabledDate: VueTypes.func,
    showTime: VueTypes.bool
  },
  setup(props, { emit }) {
    const value = ref(props.value);
    const change = (val) =>
      emit(
        'update:value',
        (val || []).map((d) => d.toDate())
      );
    watch(
      () => value,
      (val) => (value.value = (val || []).map((d) => dayjs(d)))
    );
    return {
      valueDate: ref(value.value.map((d) => dayjs(d))),
      change,
    };
  },
});
</script>

<style lang="scss" scoped>

</style>
