import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import {DataService} from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';

const state = () => ({
  nationalities: [],
  loading: false,
  error: null,
});
const actions = {
  async getAllNationalities({ state, commit }) {
    try {
      commit('fetchDataBegin');
      const response = await DataService.get(`${ConstantAPI.NATIONALITY.GET_ALL.url}`);
      state.nationalities = response.data
      return commit('fetchDataSuccess', response.data);
    } catch (err) {
      commit('fetchDataErr', err);
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
      ...mutations,
      updateField,
  },
  getters: {
      getField,
  },
};

