<template>
  <div class="table-custom h-full relative">
    <a-table
      class="mb-25 scroll-table-to-top"
      :class="pagination ? 'h-[calc(100%-72px-25px)]' : 'h-full'"
      :columns="tableColumns"
      :data-source="data"
      :pagination="clientPagination"
      :tableLayout="tableLayout"
      :showSorterTooltip="showSorterTooltip"
      :loading="loading"
      :rowSelection="rowSelection"
      :hasCheckbox="hasCheckbox"
      :scroll="{ x: 1500, y: scrollHeight, scrollToFirstRowOnChange: true }"
      @resizeColumn="handleResizeColumn"
      @change="
        (pagination, filters, sorter) =>
          $emit('change', { pagination, filters, sorter })
      "
      :locale="locale"
    >
      <template v-if="title" #title>{{ title }}</template>
      <template #headerCell="{ column }">
        <slot name="custom-header" :column="column">
          <div class="uppercase">{{ column.title }}</div>
        </slot>
      </template>
      <template #bodyCell="{ column, record }">
        <slot name="custom-body" :column="column" :record="record"></slot>
      </template>
      <template v-if="hasEmptyText" #emptyText>
        <slot name="empty-text"></slot>
      </template>
      <slot name="custom-column"></slot>
    </a-table>
    <div class="relative" v-if="pagination">
      <img
        :src="require(`../../static/img/bg-pagination.png`)"
        alt="bg-pagination"
        width="1604"
        height="72"
        class="h-[72px] w-full"
      />
      <span
        class="absolute bottom-[10px] text-ems-gray500 text-sm right-[120px]"
        >{{ t('common.show_records/pages') }}</span
      >
      <a-pagination
        v-if="pagination && total"
        :show-size-changer="showSizeChanger"
        v-model:current="page"
        v-model:page-size="size"
        :total="total"
        :page-size-options="pageSizeOptions"
        show-quick-jumper
        @change="onChangePage"
        @show-size-change="sizeChange"
        :show-total="
          showTotal ? (total) => `${t('common.total')}: ${total}` : ''
        "
      >
      </a-pagination>
    </div>
  </div>
</template>

<script src="./table-controller.js"></script>
<style lang="scss">
.table-custom {
  --tw-bg-opacity: 1;
  background-color: rgba(23, 23, 23, var(--tw-bg-opacity));
}
.table-custom .ant-table {
  border-radius: 0px !important;
}
.table-custom .ant-table .ant-table-thead tr th:first-child {
  border-radius: 0px !important;
}
.table-custom .ant-table .ant-table-thead tr th:last-child {
  border-radius: 0px !important;
}
.table-custom .ant-table .ant-table-thead tr th {
  border-bottom: 1px solid #373737;
  border-top: 1px solid #373737;
  --tw-bg-opacity: 1;
  background-color: rgba(38, 38, 38, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(196, 196, 196, var(--tw-text-opacity));
}
.table-custom .ant-table .ant-table-thead tr th .ant-table-filter-column {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
.table-custom .ant-table .ant-table-thead tr th .ant-table-filter-column .ant-table-column-title {
  -webkit-box-flex: 0;
  -ms-flex: none;
  -webkit-flex: none;
  flex: none;
}
.table-custom .ant-table .ant-table-thead tr th .ant-table-column-sorters {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
.table-custom .ant-table .ant-table-thead tr th .ant-table-column-sorters .ant-table-column-title {
  -webkit-box-flex: 0;
  -ms-flex: none;
  -webkit-flex: none;
  flex: none;
}
.table-custom .ant-table .ant-checkbox-checked .ant-checkbox-inner {
  --tw-bg-opacity: 1;
  background-color: rgba(181, 18, 46, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(181, 18, 46, var(--tw-border-opacity));
}
.table-custom .ant-table .ant-checkbox-inner {
  background-color: transparent;
  --tw-border-opacity: 1;
  border-color: rgba(196, 196, 196, var(--tw-border-opacity));
}
.table-custom .ant-table .ant-table-tbody tr {
  --tw-text-opacity: 1;
  color: rgba(196, 196, 196, var(--tw-text-opacity));
}
.table-custom .ant-table .ant-table-tbody tr:nth-child(odd) {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 38, 38, var(--tw-bg-opacity));
}
.table-custom .ant-table .ant-table-tbody tr:nth-child(odd) td {
  background: inherit !important;
  --tw-bg-opacity: 1;
  background-color: rgba(38, 38, 38, var(--tw-bg-opacity));
}
.table-custom .ant-table .ant-table-tbody tr:nth-child(even) {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 31, 31, var(--tw-bg-opacity));
}
.table-custom .ant-table .ant-table-tbody tr:nth-child(even) td {
  background: inherit !important;
  --tw-bg-opacity: 1;
  background-color: rgba(31, 31, 31, var(--tw-bg-opacity));
}
.table-custom .ant-table .ant-table-tbody tr:hover {
  opacity: 0.9;
}
.table-custom .ant-table .ant-table-tbody tr td {
  border-bottom: 1px solid #373737;
}
.table-custom .ant-pagination {
  margin: 0 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  padding-left: 22px;
  padding-right: 22px;
  position: absolute;
  bottom: 10px;
  right: 0px;
  width: 100%;
}
.table-custom .ant-pagination .ant-pagination-total-text {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
  height: 20px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(123, 123, 123, var(--tw-text-opacity));
}
.table-custom .ant-pagination .ant-pagination-options {
  flex: 1;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.table-custom .ant-pagination .ant-pagination-options .ant-pagination-options-quick-jumper {
  display: none;
}
.table-custom .ant-pagination .ant-pagination-options .ant-select {
  float: right;
  --tw-bg-opacity: 1;
  background-color: rgba(111, 111, 111, var(--tw-bg-opacity));
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 20px;
  width: 80px;
}
.table-custom .ant-pagination .ant-pagination-options .ant-select:before {
  position: absolute;
  content: '';
  width: 10px;
  left: 0;
  height: 6px;
  top: 0px;
  background-color: #191919;
  clip-path: polygon(100% 0%, 0% 100%, 0% 0%, 0% 0%);
}
.table-custom .ant-pagination .ant-pagination-options .ant-select:after {
  position: absolute;
  content: '';
  width: 10px;
  right: 0;
  height: 6px;
  bottom: 0px;
  background-color: #191919;
  clip-path: polygon(100% 0%, 0% 100%, 100% 100%, 100% 0%);
}
.table-custom .ant-pagination .ant-pagination-options .ant-select .ant-select-selector {
  border: none !important;
  height: 18px !important;
  clip-path: polygon(
              10px 0%,
              calc(100% + 20px) 0%,
              calc(100% - 10px) 100%,
              -20px 100%
            );
  width: calc(100% - 4px);
  border-radius: 0;
  --tw-bg-opacity: 1;
  background-color: rgba(25, 25, 25, var(--tw-bg-opacity));
}
.table-custom .ant-pagination .ant-pagination-options .ant-select .ant-select-selector .ant-select-selection-item {
  line-height: 16px !important;
  overflow: visible;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(123, 123, 123, var(--tw-text-opacity));
}
.table-custom .ant-pagination .ant-pagination-options .ant-select .ant-select-arrow {
  color: #b5122e;
}
.table-custom .ant-pagination .ant-pagination-options .ant-select .ant-select-dropdown {
  background-color: #191919;
}
.table-custom .ant-pagination .ant-pagination-options .ant-select .ant-select-dropdown .ant-select-item-option-content {
  --tw-text-opacity: 1;
  color: rgba(123, 123, 123, var(--tw-text-opacity));
}
.table-custom .ant-pagination .ant-pagination-options .ant-select-dropdown-placement-topLeft {
  width: 100px !important;
  left: -10px !important;
}
.table-custom .ant-pagination .ant-pagination-item-container {
  width: 100%;
}
.table-custom .ant-pagination .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: #b4b4b4 !important;
}
.table-custom .ant-pagination .ant-pagination-item {
  background-color: #202020;
  color: #b4b4b4;
  font-size: 14px;
  border: none !important;
  margin-bottom: 13px !important;
}
.table-custom .ant-pagination .ant-pagination-item a {
  color: #b4b4b4;
}
.table-custom .ant-pagination .ant-pagination-item-active {
  background-color: #b5122e !important;
  color: #f5f5f5;
  text-shadow: 0px 0px 6px #fff;
  border: none !important;
}
.table-custom .ant-pagination .ant-pagination-next {
  border: none !important;
  margin-bottom: 13px !important;
}
.table-custom .ant-pagination .ant-pagination-prev {
  border: none !important;
  margin-bottom: 13px !important;
}
.table-custom .ant-pagination .ant-pagination-jump-next, .table-custom .ant-pagination .ant-pagination-jump-prev {
  border: none !important;
  margin-bottom: 13px !important;
}
.table-custom .ant-pagination .ant-pagination-item-link {
  --tw-bg-opacity: 1;
  background-color: rgba(32, 32, 32, var(--tw-bg-opacity));
  border-radius: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  --tw-text-opacity: 1;
  color: rgba(181, 18, 46, var(--tw-text-opacity));
}
</style>
