import { flatten } from '@/util/common-utils';


export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  getAllSuccess(state, data) {
    state.tree = data;
    state.groups = flatten(data);
    state.loading = false;
  },
  preCreate(state) {
    state.group = {
      name: '',
      desc: '',
      status: 1,
      selectedFunction: [],
      selectedImage:[],
      selectedGroup: [],
      selectedCamera: [],
      listUsers: [],
    };
    state.action = 2;
    state.listUserInRole = []
  },
  async setFormValue(state, { group, allMenusEms, allMenusVms, allGroups, allCamera, listDataUser }) {
    let keyMenuEms = [];
    let keyMenuVms = [];
    let keyGroup = [];
    let keyCamera = [];

    function handleTraverse(listAll, group, key, type) {
        if (!Array.isArray(listAll)) return;

        listAll.forEach((item) => {
            let matchGroup;
            if (type === 'menu' && Array.isArray(group.roleMenus)) {
                matchGroup = group.roleMenus.find(p => item.id === p.menuId);
            } else if (type === 'group' && Array.isArray(group.roleGroups)) {
                matchGroup = group.roleGroups.find(p => item.id === p.groupId);
            } else if (type === 'camera' && Array.isArray(group.roleCameras)) {
                matchGroup = group.roleCameras.find(p => item.id === p.cameraId);
            }

            if (matchGroup) {
                key.push(item.id);
            }

            if (item.children !== null && Array.isArray(item.children)) {
                handleTraverse(item.children, group, key, type);
            }
        });
    }

    const keyUser = [];
    if (Array.isArray(listDataUser)) {
        listDataUser.forEach(item => {
            keyUser.push({
                id: item.id,
                name: item.fullName,
                group: item.rolesName.split(', '),
                email: item.email,
                phone: item.phone
            });
        });
    }

    handleTraverse(allMenusEms, group, keyMenuEms, 'menu');
    handleTraverse(allMenusVms, group, keyMenuVms, 'menu');
    handleTraverse(allGroups, group, keyGroup, 'group');
    handleTraverse(allCamera, group, keyCamera, 'camera');

    state.group.selectedFunctionParent = keyMenuEms;
    state.group.selectedImageParent = keyMenuVms;
    state.group.selectedGroupParent = keyGroup;
    state.group.selectedCameraParent = keyCamera;

    let listMenuParent = [];

    function handleGetKeyMenus(key, menu) {
        if (!Array.isArray(key) || !Array.isArray(menu)) return;

        key.forEach((item) => {
            menu.forEach((p) => {
                if (p.parentId && item === p.parentId) {
                    if (!listMenuParent.includes(p.parentId)) {
                        listMenuParent.push(p.parentId);
                    }
                } else if (p.children !== null && Array.isArray(p.children)) {
                    handleGetKeyMenus(key, p.children);
                }
            });
        });
    }

    function handleGetKeyCamera(key, menu) {
        if (!Array.isArray(key) || !Array.isArray(menu)) return;

        key.forEach((item) => {
            menu.forEach((p) => {
                if (p.parent_id && item === p.parent_id) {
                    if (!listMenuParent.includes(p.parent_id)) {
                        listMenuParent.push(p.parent_id);
                    }
                } else if (p.children !== null && Array.isArray(p.children)) {
                    handleGetKeyCamera(key, p.children);
                }
            });
        });
    }

    handleGetKeyMenus(keyMenuEms, allMenusEms);
    keyMenuEms = keyMenuEms.filter(item => !listMenuParent.includes(item));

    handleGetKeyMenus(keyMenuVms, allMenusVms);
    keyMenuVms = keyMenuVms.filter(item => !listMenuParent.includes(item));

    handleGetKeyMenus(keyGroup, allGroups);
    keyGroup = keyGroup.filter(item => !listMenuParent.includes(item));

    handleGetKeyCamera(keyCamera, allCamera);
    keyCamera = keyCamera.filter(item => !listMenuParent.includes(item));

    state.visible = true;
    state.group.name = group.name ? group.name : '';
    state.group.desc = group.description ? group.description : '';
    state.group.status = group.status ? group.status : 0;
    state.group.selectedFunction = keyMenuEms || [];
    state.group.selectedImage = keyMenuVms || [];
    state.group.selectedGroup = keyGroup || [];
    state.group.selectedCamera = keyCamera || [];
    state.group.listUsers = keyUser || [];
    state.loading = false;
}

};
