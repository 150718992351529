import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { checkResponse } from '@/util/common-utils';
import { notification } from 'ant-design-vue';
import { FORM_MODE } from '@/util/common-constant';
import { i18n } from '@/main';
const state = () => ({
  list: [],
  loading: false,
  visibleMenu: false,
  visibleButton: false,
  visibleIcon: false,
  api: ConstantAPI.menu,
  menuEms: [],
  menuVms: [],
});

const actions = {
  async getAll({ commit, state }) {
    try {
      commit('setLoading', true);
      const response = await DataService.callApi(state.api.SEARCH);
      return commit('getAllSuccess', response.data);
    } catch (err) {
      console.log(err);
      commit('setLoading', false);
    }
  },
  async getMenuEms({ commit, state }) {
    try {
      const response = await DataService.get(state.api.SEARCH.url, {
        clientId: 1,
      });
      state.menuEms = response.data;
    } catch (err) {
      console.log(err);
      commit('setLoading', false);
    }
  },
  async getMenuVms({ commit, state }) {
    try {
      const response = await DataService.get(state.api.SEARCH.url, {
        clientId: 2,
      });
      state.menuVms = response.data;
    } catch (err) {
      console.log(err);
      commit('setLoading', false);
    }
  },
  async preView({ commit }, row) {
    commit('setFormValue', {
      menu: row,
      formMode: FORM_MODE.VIEW,
    });
  },
  async preUpdate({ commit }, row) {
    commit('setFormValue', {
      menu: row,
      formMode: FORM_MODE.UPDATE,
    });
  },
  async preEditMenu({ commit }) {
    commit('preEditMenu');
  },
  async preCreateMenu({ commit }) {
    commit('preCreateMenu');
  },
  async preCreateButton({ commit }) {
    commit('preCreateButton');
  },
  async preChooseIcon({ commit }) {
    commit('preChooseIcon');
  },
  async setVisibleMenu({ state }, visible) {
    state.visibleMenu = visible;
  },
  async setVisibleButton({ state }, visible) {
    state.visibleButton = visible;
  },
  async setVisibleIcon({ state }, visible) {
    state.visibleIcon = visible;
  },
  async createMenu({ commit, state }, payload) {
    const { t } = i18n.global;
    let type;
    if (payload.type === 0) {
      type = t('menu.menu');
    } else {
      type = t('menu.button');
    }
    try {
      commit('setLoading', true);
      const response = await DataService.callApi(
        state.api.CREATE,
        payload,
        null
      );
      let success = false;
      
      if(response.code === '404'){
        success = false;
        notification.error({
          message: t('common.notification'),
          description: t('common.orderNum_exist', { name: type }),
          duration: 4,
        });
      }
      else{
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('common.create_success', { name: type }),
            duration: 4,
          });
        });
      }
      return success;
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async updateMenu({ commit, state }, payload) {
    const { t } = i18n.global;
    let type;
    if (payload.type === 0) {
      type = t('menu.menu');
    } else {
      type = t('menu.button');
    }
    try {
      commit('setLoading', true);
      const response = await DataService.callApi(
        state.api.UPDATE,
        {
          ...payload,
          id: state.menu.id,
        },
        null
      );
      let success = false;
      checkResponse(response, () => {
        success = true;
        notification.success({
          message: t('common.notification'),
          description: t('common.update_success', { name: type }),
          duration: 4,
        });
      });
      return success;
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async delete({ state }, rows) {
    const { t } = i18n.global;
    try {
      if (!rows.length) {
        notification.error({
          message: t('common.notification'),
          description: t('common.no_record_selected'),
          duration: 4,
        });
        return false;
      }
      const response = await DataService.delete(
        state.api.DELETE.url + '/' + rows.map((e) => e && e.id).join(',')
      );
      let success = false;
      checkResponse(response, () => {
        success = true;
        notification.success({
          message: t('common.notification'),
          description: t('common.delete_success'),
          duration: 4,
        });
      });
      return success;
    } catch (err) {
      console.log(err);
      return false;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
