const routes = [
  {
    path: 'login',
    name: 'login',
    component: () =>
      import(
        /* webpackChunkName: "auth" */ '@/view/authentication/SignIn.vue'
      ),
  },
  {
    path: 'forgot',
    name: 'forgot',
    component: () =>
        import(
            /* webpackChunkName: "auth" */ '@/view/authentication/Forgot.vue'
            ),
  },
  {
    path: 'reset-password',
    name: 'reset-password',
    component: () =>
        import(
            /* webpackChunkName: "auth" */ '@/view/authentication/ResetPassword.vue'
            ),
  },
];

export default routes;
