import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { checkResponse } from '@/util/common-utils';
import { notification } from 'ant-design-vue';
import { i18n } from '@/main';
import dayjs from 'dayjs';

const state = () => ({
  tracingObjectDetail: {
    frequency: {
      statistic: [],
      camera_info: [],
    },
  },
  locationData: {},
  cameras: [],
  dataChart: [],
  isLoading: false,
  listGroupCamera: [],
  listCamera: [],
  listCameraByGroup: [],
  listFrequency: [],
  listIntrusion: [],
  totalIntrusion: 0,
  listInOut: [],
  totalInOut: 0,
});

const actions = {
  async getDetail({ state }, { id, params }) {
    try {
      state.isLoading = true;
      const response = await DataService.callApi(
        {
          url: ConstantAPI.TRACING_OBJECT.GET_BY_ID.url + id,
          method: ConstantAPI.TRACING_OBJECT.GET_BY_ID.method,
        },
        null,
        params
      );
      checkResponse(response, () => {
        Object.assign(state.tracingObjectDetail, response.data);
        if (response.data.frequency.statistic) {
          state.dataChart = response.data.frequency.statistic
            .map((item) => ({
              name: item.name ? item.name : item.profileUUID,
              value: item.count,
              id: item.profileUUID,
            }))
            .slice(0, 10);
        } else {
          state.dataChart = [];
        }
        state.isLoading = false;
      });
    } catch (err) {
      state.isLoading = false;
      console.log(err);
    }
  },
  async getLocation({ state }, { id, params }) {
    try {
      const response = await DataService.callApi(
        {
          url: ConstantAPI.TRACING_OBJECT.GET_LOCATION.url + id,
          method: ConstantAPI.TRACING_OBJECT.GET_LOCATION.method,
        },
        null,
        {
          ...params,
          dateFrom:
            params && params.dateFrom
              ? dayjs(params.dateFrom).startOf('day').toDate()
              : null,
          dateTo:
            params && params.dateTo
              ? dayjs(params.dateTo).endOf('day').toDate()
              : null,
        }
      );
      checkResponse(response, () => {
        state.locationData = response.data ? response.data : [];
      });
    } catch (err) {
      state.isLoading = false;
      console.log(err);
    }
  },
  async changeStatus({ commit }, payload) {
    try {
      let isSuccess = false;
      const response = await DataService.callApi(
        ConstantAPI.TRACING_OBJECT.CHANGE_STATUS,
        payload
      );
      checkResponse(response, () => {
        const { t } = i18n.global;
        isSuccess = true;
        notification.success({
          message: t('common.notification'),
          description: t('object_tracing.change_status_success'),
          duration: 4,
        });
      });
      commit('setLoading', false);
      return isSuccess;
    } catch (err) {
      console.log(err);
    }
  },
  resetTracingStore({ state }) {
    state.tracingObjectDetail = {};
    state.listFrequency = {};
    state.dataChart = [];
    state.isLoading = false;
  },
  async deleteTracing({ commit }, ids) {
    const { t } = i18n.global;
    let isSuccess = false;
    try {
      const response = await DataService.callApi(
        ConstantAPI.TRACING_OBJECT.TRACING,
        {
          ids: [...ids],
          tracing: false,
        }
      );
      checkResponse(response, () => {
        notification.success({
          message: t('common.notification'),
          description: t('object_tracing.delete_tracing_profile_success'),
          duration: 4,
        });
        isSuccess = true;
      });
      commit('setLoading', false);
      return isSuccess;
    } catch (err) {
      console.log(err);
    }
  },
  async getListGroupCamera({ state }, params) {
    try {
      const response = await DataService.callApi(
        ConstantAPI.TRACING_OBJECT.GET_LIST_GROUP_CAMERA,
        null,
        params
      );
      checkResponse(response, () => {
        state.listGroupCamera = response.data ? response.data : [];
      });
    } catch (err) {
      console.log(err);
    }
  },
  async getListCamera({ state }, params) {
    try {
      const response = await DataService.callApi(
        ConstantAPI.CAMERA.GET_ALL,
        null,
        params
      );
      checkResponse(response, () => {
        state.listCamera = response.data ? response.data : [];
      });
    } catch (err) {
      console.log(err);
    }
  },
  async getListCameraByGroups({ state }, listGroups) {
    let isSuccess = false;
    try {
      const response = await DataService.callApi(
        ConstantAPI.CAMERA.GET_BY_GROUPS,
        null,
        {
          groupIds: listGroups.join(','),
        }
      );
      checkResponse(response, () => {
        state.listCameraByGroup = response.data ? response.data : [];
        isSuccess = true;
      });
      return isSuccess;
    } catch (err) {
      console.log(err);
    }
  },
  async getFrequency({ state }, { id, params }) {
    try {
      state.isLoading = true;
      const response = await DataService.callApi(
        {
          url: ConstantAPI.TRACING_OBJECT.GET_FREQUENCY.url + id,
          method: ConstantAPI.TRACING_OBJECT.GET_FREQUENCY.method,
        },
        null,
        params
      );
      checkResponse(response, () => {
        state.listFrequency = response.data.statistic
          ? response.data.statistic
          : [];
      });
      state.isLoading = false;
    } catch (err) {
      state.isLoading = false;
      console.log(err);
    }
  },
  async getIntrusion({ state }, { params, reset }) {
    try {
      state.isLoading = true;
      const response = await DataService.callApi(
        ConstantAPI.EVENT.INTRUSION,
        null,
        params
      );

      if (reset) {
        state.listIntrusion = [];
      }
      checkResponse(response, () => {
        state.listIntrusion = response.data.content
          ? state.listIntrusion.concat(response.data.content)
          : state.listIntrusion;
        state.totalIntrusion = response.data.totalElements;
      });
      state.isLoading = false;
    } catch (err) {
      state.isLoading = false;
      console.log(err);
    }
  },
  resetListIntrusion({ state }) {
    state.listIntrusion = [];
  },
  newIntrusion({ state }, evt) {
    state.listIntrusion.unshift(evt);
  },
  async getInOut({ state }, { params, reset }) {
    try {
      state.isLoading = true;
      const response = await DataService.callApi(
        ConstantAPI.IN_OUT_EVENT.LIST,
        null,
        params
      );
      if (reset) {
        state.listInOut = [];
      }
      checkResponse(response, () => {
        state.listInOut = response.data.content
          ? state.listInOut.concat(response.data.content)
          : state.listInOut;
        state.totalInOut = response.data.totalElements;
      });
      state.isLoading = false;
    } catch (err) {
      state.isLoading = false;
      console.log(err);
    }
  },
  resetListInOut({ state }) {
    state.listInOut = [];
  },
  newInOut({ state }, evt) {
    state.listInOut.unshift(evt);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
