import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import {DataService} from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';

const state = () => ({
  color: [],
  vehicleType: [],
  brand: [],
  addressType: [],
  licensePlateType: [],
  loading: false,
  error: null,
  age: [],
  race: [],
});
const actions = {
  async getAllColor({ state, commit }) {
    try {
      commit('fetchDataBegin');
      const response = await DataService.callApi(ConstantAPI.APP_PARAMS, null, { type: 'Color' });
      state.color = response.data
      return commit('fetchDataSuccess', response.data);
    } catch (err) {
      commit('fetchDataErr', err);
    }
  },
  async getAllVehicleType({ state, commit }) {
    try {
      commit('fetchDataBegin');
      const response = await DataService.callApi(ConstantAPI.APP_PARAMS, null, { type: 'VehicleType' });
      state.vehicleType = response.data
      return commit('fetchDataSuccess', response.data);
    } catch (err) {
      commit('fetchDataErr', err);
    }
  },
  async getAllBrand({ state, commit }) {
    try {
      commit('fetchDataBegin');
      const response = await DataService.callApi(ConstantAPI.APP_PARAMS, null, { type: 'Brand' });
      state.brand = response.data
      return commit('fetchDataSuccess', response.data);
    } catch (err) {
      commit('fetchDataErr', err);
    }
  },
  async getAllAddressType({ state, commit }) {
    try {
      commit('fetchDataBegin');
      const response = await DataService.callApi(ConstantAPI.APP_PARAMS, null, { type: 'AddressType' });
      state.addressType = response.data
      return commit('fetchDataSuccess', response.data);
    } catch (err) {
      commit('fetchDataErr', err);
    }
  },
  async getAllLicensePlateType({ state, commit }) {
    try {
      commit('fetchDataBegin');
      const response = await DataService.callApi(ConstantAPI.APP_PARAMS, null, { type: 'LicensePlateType' });
      state.licensePlateType = response.data
      return commit('fetchDataSuccess', response.data);
    } catch (err) {
      commit('fetchDataErr', err);
    }
  },
  async getAllAge({ state, commit }) {
    try {
      commit('fetchDataBegin');
      const response = await DataService.callApi(ConstantAPI.APP_PARAMS, null, { type: 'Age' });
      state.age = response.data
      return commit('fetchDataSuccess', response.data);
    } catch (err) {
      commit('fetchDataErr', err);
    }
  },
  async getAllRace({ state, commit }) {
    try {
      commit('fetchDataBegin');
      const response = await DataService.callApi(ConstantAPI.APP_PARAMS, null, { type: 'Race' });
      state.race = response.data
      return commit('fetchDataSuccess', response.data);
    } catch (err) {
      commit('fetchDataErr', err);
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
      ...mutations,
      updateField,
  },
  getters: {
      getField,
  },
};

