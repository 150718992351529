import mutations from './mutations';
import { getField, updateField } from 'vuex-map-fields';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { checkResponse } from '@/util/common-utils';
import { TAB_WALKIE } from '@/util/common-constant.js';
import AgoraRTC from 'agora-rtc-sdk-ng';
const defaultState = {
  itemValue: null,
  isShowMember: TAB_WALKIE.WALKIE,
  api: ConstantAPI.messages,
  listDialogs: [],
  listContactsInit: [],
  listContacts: [],
  listContactSend: [],
  listChannels: [],
  listChannelsInit: [],
  historyMessages: null,
  totalElements: null,
  totalElementsContact: null,
  totalElementsChannel: null,
  listParticipants: [],
  totalElementsMember: null,
  currentLocation: {},
  listStatus: [],
  isLoadingMess: false,
  listUserConnected: [],
  isShowDeleted: {
    active: false,
    type: null,
  },
  contentMessShare: null,
  client: null,
  localTracks: {
    audioTrack: null,
  },
  remoteUsers: {},
  inChannel: false,
  talking: false,
  idConnected: null,
  roomID:null,
  params:null,
  component:null
};
const state = () => ({
  ...defaultState,
});
const actions = {
  async getMessagesByDialogs({ state }, payload) {
    state.isLoadingMess = true;
    const { dialogId, dialogType, isChated } = payload;
    try {
      if (isChated) {
        const response = await DataService.callApi(
          state.api.GET_HISTORY_MESSAGES,
          null,
          { dialogId, dialogType }
        );
        checkResponse(response, () => {
          state.historyMessages = response.data ? response.data : [];
          state.isLoadingMess = false;
        });
      } else {
        state.historyMessages = [];
        state.isLoadingMess = false;
      }
    } catch (err) {
      console.log(err);
    }
  },
  async getListDialogs({ state }, value) {
    const { payload, firstData } = value;
    try {
      const response = await DataService.callApi(
        state.api.GET_LIST_DIALOGS,
        null,
        payload
      );
      if (firstData) {
        state.listDialogs = [];
      }
      checkResponse(response, () => {
        state.listDialogs = response.data.content
          ? state.listDialogs.concat(response.data.content)
          : state.listDialogs;
        state.totalElements = response.data.totalElements;
      });
    } catch (err) {
      console.log(err);
    }
  },
  async getListContacts({ state, commit }) {
    try {
      const response = await DataService.callApi(
        state.api.GET_LIST_CONTACT,
        null
      );
      checkResponse(response, () => {
        state.totalElementsContact = response.data.totalElements;
        state.listContactSend = response.data ? response.data.content:[]
        state.listContactsInit = response.data.content
        state.listContactSend = response.data ? response.data.content : [];
        commit('setInitData', response.data.content);
      });
    } catch (err) {
      console.log(err);
    }
  },
  async getListChannels({ state, commit }, value) {
    const { payload, firstData } = value;
    try {
      const response = await DataService.callApi(
        ConstantAPI.channel.GET_CHANNEL,
        null,
        payload
      );
      if (firstData) {
        state.listChannels = [];
      }
      checkResponse(response, () => {
        state.listChannels = response.data.content
          ? state.listChannels.concat(response.data.content)
          : state.listChannels;
        state.totalElementsChannel = response.data.totalElements;
        state.listChannelsInit = response.data.content;
        commit('setInitDataChannels', response.data.content);
      });
    } catch (err) {
      console.log(err);
    }
  },
  async sendMessages({ state }, payload) {
    try {
      const response = await DataService.callApi(
        state.api.SEND_MESSAGES,
        payload
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
  async shareMessages({ state }, payload) {
    try {
      const response = await DataService.callApi(
        state.api.SHARE_MESSAGES,
        payload
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
  async updateMessage({ state }, payload) {
    if (!state.historyMessages) {
      state.historyMessages = { messages: [] };
    }
    if (!Array.isArray(state.historyMessages.messages)) {
      state.historyMessages.messages = [];
    }
    const exists = state.historyMessages.messages.some(
      (message) => message.messageIdStr === payload.messageIdStr
    );
    if (!exists) {
      let arrMess = [];
      arrMess.push(payload);
      state.historyMessages.messages = arrMess.concat(
        state.historyMessages.messages
      );
    } else {
      console.log('Duplicate message, ignoring:', payload.messageIdStr);
    }
  },
  async readMessages({ state }, payload) {
    try {
      const response = await DataService.callApi(
        state.api.READ_MESSAGES,
        payload
      );
      checkResponse(response, () => {});
    } catch (err) {
      console.log(err);
    }
  },
  async viewMember({ state }, value) {
    try {
      state.isShowMember = value;
    } catch (err) {
      console.log(err);
    }
  },
  async selectValue({ state }, value) {
    state.itemValue = value;
  },
  async deleteSingleMess({ state }, payload) {
    try {
      const response = await DataService.callApi(
        state.api.DELETE_MESSAGES,
        payload
      );
      checkResponse(response, () => {});
    } catch (err) {
      console.log(err);
    }
  },
  async deleteAllMess({ state }, payload) {
    try {
      const response = await DataService.callApi(
        state.api.DELETE_HISTORY,
        payload
      );
      checkResponse(response, () => {});
    } catch (err) {
      console.log(err);
    }
  },
  async getParticipants({ state }, payload) {
    try {
      const response = await DataService.get(
        `${ConstantAPI.channel.GET_PARTICIPANTS.url}?channelId=${payload}&size=9999`
      );
      state.listParticipants = response.data ? response.data.content : [];
      state.totalElementsMember = response.data
        ? response.data.totalElements
        : 0;
      return response.data.content;
    } catch (err) {
      console.log(err);
    }
  },
  async getDialogsId(_, id) {
    try {
      const response = await DataService.get(
        `${ConstantAPI.messages.GET_DIALOGID.url}?peerId=${id}`
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
  async handleLikeContact({ state }, payload) {
    try {
      const response = await DataService.callApi(
        state.api.LIKE_CONTACT,
        payload
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  async handleUnLikeContact({ state }, payload) {
    try {
      const response = await DataService.callApi(
        state.api.UN_LIKE_CONTACT,
        payload
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  async handleLikeChannel({ state }, payload) {
    try {
      const response = await DataService.callApi(
        state.api.LIKE_CHANNEL,
        payload
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  async handleUnLikeChannel({ state }, payload) {
    try {
      const response = await DataService.callApi(
        state.api.UN_LIKE_CHANNEL,
        payload
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  async getListStatus({ state }, ids) {
    try {
      const response = await DataService.get(
        ConstantAPI.STATUS.GET_LIST_STATUS.url + `/${ids}`
      );
      checkResponse(response, () => {
        state.listStatus = response.data;
      });
    } catch (err) {
      console.log(err);
    }
  },
  async updateLocation({ state }, location) {
    try {
      const response = await DataService.callApi(
        ConstantAPI.STATUS.UPDATE_LOCATION,
        location
      );
      checkResponse(response, () => {
        state.currentLocation = location;
      });
    } catch (err) {
      console.log(err);
    }
  },
  async createPhoneRoom({ state }, payload) {
    try {
      const response = await DataService.callApi(
        state.api.CREATE_PHONE_ROOOM,
        payload
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
  async joinRoom({ state }, payload) {
    try {
      const response = await DataService.callApi(state.api.JOIN_ROOOM, payload);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
  async leaveRoom({ state }, payload) {
    try {
      const response = await DataService.callApi(state.api.LEAVE_ROOM, payload);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
  async handleAddUserConnected({ state }, payload) {
    const userExists = state.listUserConnected.some((user) => user === payload);
    if (!userExists) {
      state.listUserConnected.push(payload);
    }
  },
  async isShowDelete({ state }, value) {
    try {
      state.isShowDeleted = value;
    } catch (err) {
      console.log(err);
    }
  },
  resetState({ state }) {
    Object.assign(state, { ...defaultState });
  },
  async selectMess({ state }, value) {
    try {
      state.contentMessShare = value;
    } catch (err) {
      console.log(err);
    }
  },
  async joinChannel({ commit, state }, { appId, channel, token, uuid }) {
    if (!state.client) {
      const client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });
      client.on('user-published', (user) => {
        client.subscribe(user, 'audio').then(() => {
          user.audioTrack.play();
          commit('ADD_REMOTE_USER', user);
        });
      });
      client.on('user-unpublished', (user) => {
        commit('REMOVE_REMOTE_USER', user.uid);
      });
      commit('SET_CLIENT', client);
    }
    try {
      await state.client.join(appId, channel, token, uuid);
      state.localTracks.audioTrack =
        await AgoraRTC.createMicrophoneAudioTrack();
      state.localTracks.audioTrack.setEnabled(false);
      commit('SET_LOCAL_TRACKS', { audioTrack: state.localTracks.audioTrack });

      await state.client.publish(state.localTracks.audioTrack);
      console.log('publish success!');
      commit('SET_IN_CHANNEL', true);
    } catch (error) {
      console.error('Failed to join channel:', error);
    }
  },
  async leaveChannel({ commit, state }) {
    if (state.localTracks.audioTrack) {
      state.localTracks.audioTrack.stop();
      state.localTracks.audioTrack.close();
      commit('SET_LOCAL_TRACKS', { audioTrack: null });
    }
    if (state.client) {
      try {
        await state.client.leave();
        console.log('Client leaves channel success');
      } catch (error) {
        console.error('Failed to leave channel:', error);
      }
    } else {
      console.error('Failed to leave channel: client is null or undefined');
    }
    commit('SET_IN_CHANNEL', false);
    commit('SET_TALKING', false);
    commit('SET_REMOTE_USER', {});
  },
  startTalking({ commit, state }) {
    if (!state.talking && state.localTracks.audioTrack) {
      state.localTracks.audioTrack.setEnabled(true);
      commit('SET_TALKING', true);
      console.log('Start talking');
    }
  },
  stopTalking({ commit, state }) {
    if (state.talking && state.localTracks.audioTrack) {
      state.localTracks.audioTrack.setEnabled(false);
      commit('SET_TALKING', false);
      console.log('Stop talking');
    }
  },
  handleChangeStatus({ state }, value) {
    state.idConnected = value;
  },
  handleGetRoomID({ state }, value) {
    state.roomID = value;
  },
  handleSetParams({ state }, value) {
    try {
      state.params = value;
    } catch (err) {
      console.log(err);
    }
  },
  handleSetComponent({ state }, value) {
    try {
      state.component = value;
    } catch (err) {
      console.log(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
