import { flatten } from '@/util/common-utils';

export default {
  fetchDataBegin(state) {
    state.loading = true;
  },
  fetchDataSuccess(state, data) {
    state.loading = false;
    state.treeGroup = data;
    state.groups = flatten(data);
    state.loading = false;
  },

  getListIOSuccess(state, data) {
    state.listIOInGroup = state.listIOInGroup.concat(data);
    state.loading = false;
  },
  fetchDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },
  preCreate(state) {
    state.group = {
      name: '',
      parentId: null,
      children: [],
      identityObject: [],
      description: '',
    };
  },
  setFormValue(state, { group, ioGroup }) {
    state.visible = true;
    if (state.listIOInGroup && ioGroup) {
      ioGroup.forEach((e) => state.listIOInGroup.push(e));
    }
    state.group = {
      ...group,
      children: (group.children || []).map((e) => e.id),
      ioGroup: (ioGroup || []).map((e) => e.referenceId),
      permissions: [
        {
          order: 0,
          dashboard: false,
          event: false,
          map: false,
          io: false,
          view: false,
          create: false,
          update: false,
          delete: false,
        },
      ],
    };
    state.loading = false;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  getWarningLevelSuccess(state, data) {
    data.map((el) => {
      el.title = `group-management.${el.name}`;
    });
    state.warningLevel = data;
  },
};
