import app from '../../config/configApp';
import Cards from '@/components/cards/frame/CardsFrame.vue';
import FeatherIcons from '@/components/utilities/featherIcons.vue';
import Alerts from '@/components/alerts/Alerts.vue';
import Button from '@/components/buttons/Buttons.vue';
import { PageHeader } from '@/components/pageHeaders/PageHeaders';
import Heading from '@/components/heading/Heading';
import Dropdown from '@/components/dropdown/Dropdown';
import Popover from '@/components/popup/Popup';
import Loading from '@/components/loading/index';
import Modal from '@/components/modals/Modals';
import _ from 'lodash';
import DateRangePicker from '@/components/date-picker/DateRangePicker';
import DatePicker from '@/components/date-picker/DatePicker';
import Input from '@/components/input/Input';
import Tree from '@/components/tree/Tree';
import TreeSelect from '@/components/tree-select/index';
import SearchFormButton from '@/components/search-form-button/index';
import Select from '@/components/select/index';
import Table from '@/components/table/Table';
import ActionButton from '@/components/action-button/index';
import FromToDate from '@/components/from-to-date/index';

[
  Heading,
  Cards,
  Alerts,
  Button,
  Loading,
  { name: 'PageHeader', ...PageHeader },
  Dropdown,
  Popover,
  FeatherIcons,
  Modal,
  SearchFormButton,
  DateRangePicker,
  Input,
  Tree,
  TreeSelect,
  Select,
  FromToDate,
  DatePicker,
  Table,
  ActionButton,
].map((c) => {
  const name = _.snakeCase(c.name).replace(/_/g, '-');
  app.component(`cds-${name}`, c);
});
