<template>
  <template v-if="readOnly">
    {{ formatDateVN(value) }}
  </template>
  <DatePickerWrapper v-else class="!h-39px">
    <a-date-picker
      v-model:value="valueDate"
      @change="change"
      :disabled-date="disabledDate"
      :placeholder="placeholder"
      :format="format"
      :show-time="showTime"
      class="w-full !h-39px rounded-4px bg-ems-gray700 !border-ems-gray700"
      :class="isRounded ? 'rounded-4px' : 'rounded-0'"
      size="small"
      :disabled="disabled"
      :picker="picker"
    >
    </a-date-picker>
  </DatePickerWrapper>
</template>

<script>
import { computed, defineComponent, ref, watch } from 'vue';
import { DatePickerWrapper } from './style';
import VueTypes from 'vue-types';
import dayjs from 'dayjs';
import { formatDate } from '@/util/common-utils';

export default defineComponent({
  name: 'DatePicker',
  components: {
    DatePickerWrapper,
  },
  props: {
    value: VueTypes.oneOfType([
      VueTypes.string,
      VueTypes.oneOfType([VueTypes.string]),
    ]),
    format: VueTypes.string.def('DD/MM/YYYY'),
    disabledDate: VueTypes.func,
    placeholder: VueTypes.string,
    showTime: VueTypes.oneOfType([VueTypes.bool.def(false), VueTypes.object]),
    readOnly: VueTypes.bool.def(false),
    disabled: VueTypes.bool.def(false),
    isRounded: VueTypes.bool.def(false),
    picker: VueTypes.string.def('date'),
  },
  setup(props, { emit }) {
    const valueDate = ref(props.value ? dayjs(props.value) : null);
    const change = (val) => {
      emit('update:value', val ? val.toDate() : null);
      emit('change');
    };
    watch(
      () => props.value,
      (val) => (valueDate.value = val ? dayjs(val) : null)
    );
    const formatDateVN = computed(
      () => (date) => formatDate(date, props.format)
    );
    return {
      valueDate,
      change,
      formatDateVN,
    };
  },
});
</script>

<style>
.ant-picker-small:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgba(196, 196, 196, var(--tw-border-opacity)) !important;
}
.ant-picker-suffix {
  --tw-text-opacity: 1 !important;
  color: rgba(196, 196, 196, var(--tw-text-opacity)) !important;
}
.ant-picker-focused {
  box-shadow: none;
  --tw-border-opacity: 1 !important;
  border-color: rgba(196, 196, 196, var(--tw-border-opacity)) !important;
}
.ant-picker-input input {
  --tw-text-opacity: 1 !important;
  color: rgba(196, 196, 196, var(--tw-text-opacity)) !important;
}
.ant-picker-clear, .ant-picker.ant-picker-disabled {
  --tw-text-opacity: 1 !important;
  color: rgba(196, 196, 196, var(--tw-text-opacity)) !important;
  --tw-bg-opacity: 1 !important;
  background-color: rgba(67, 67, 67, var(--tw-bg-opacity)) !important;
}
.ant-picker {
  min-width: 100px;
}
.ant-picker-input input::placeholder {
  --tw-text-opacity: 1;
  color: rgba(123, 123, 123, var(--tw-text-opacity));
}
</style>
