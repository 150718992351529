import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { checkResponse } from '@/util/common-utils';
import { notification } from 'ant-design-vue';
import { i18n } from '@/main';

const state = () => ({
  tree: [],
  loading: false,
  group: {
    id:null,
    name: '',
    desc: '',
    status: 1,
    selectedFunction: [],
    selectedImage:[],
    selectedGroup: [],
    selectedCamera: [],
    selectedFunctionParent: [],
    selectedImageParent:[],
    selectedGroupParent: [],
    selectedCameraParent: [],
    listUsers: [],
  },
  totalUser:0,
  users: [],
  groups: [],
  api: ConstantAPI.role,
  visible: false,
  action: 0,
  listTreeCamera:[],
  listUserInRole:[],
  roleId:0,
  treeGroup:[]
});

const actions = {
  changeAction({ state }, action) {
    state.action = action;
  },
  addListUser({state},data){
    state.group.listUsers=data
  },
  async getListUserInRole({state},action){
    try {
      const response = await DataService.callApi(
        state.api.GET_USER_IN_ROLE,
        null,
        { roleId: action.roleId, query: action.query }
      );
      
      checkResponse(response, () => {
        if (Array.isArray(response.data)) {
          state.listUserInRole = response.data.map((item) => ({
            id: item.id,
            name: item.fullName,
            group: item.rolesName ? item.rolesName.split(', ') : [],
            email: item.email,
            phone: item.phone
          }));
        } else {
          state.listUserInRole = [];
        }
      });
    } catch (err) {
      console.log(err);
    }
  },
  async getTreeGroup({ state }, params) {
    try {
      let arrGroup = []
      const response = await DataService.callApi(state.api.GET_TREE, null, {
        type: '1',
        ...params,
      });
      arrGroup = response.data;
      arrGroup.push({
        id: 0, name: 'Không thuộc nhóm', key: 0 
      })
      state.treeGroup = arrGroup
    } catch (err) {
      console.log(err);
    }
  },
  handleSelectMutipleUsers({state},row){
    const index = state.group.listUsers.findIndex(user => user.id === row.id);
    if (index !== -1) {
      state.listUserInRole.splice(index, 1);
    } else {
      state.listUserInRole.push(row);
    }
  },
  async getListTreeCamera({ state }, params) {
    try {
      const response = await DataService.callApi(
        state.api.GET_TREE_CAMERA,
        null,
        params
      );
      checkResponse(response, () => {
        state.listTreeCamera = response.data ? response.data
        : [];
      });
    } catch (err) {
      console.log(err);
    }
  },
  async getListUsers({state},action) {
    try {
      const response = await DataService.callApi(ConstantAPI.user.SEARCH, null, {
        username:action.username,
        page:action.page-1,
        size:10
      });
      state.totalUser = response.data.totalElements
      state.users = response.data.content.map((item)=>
        ({
          id:item.id,
          name:item.fullName,
          group:item.rolesName ? item.rolesName.split(', ') : [],
          email:item.email,
          phone:item.phone
        })
      )
    } catch (err) {
      console.log(err);
    }
  },
  async getAll({ commit, state }) {
    try {
      const response = await DataService.callApi(state.api.SEARCH, null, {
        type: 2,
      });
      return commit('getAllSuccess', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async preCreate({ commit }) {
    commit('preCreate');
  },
  async preUpdate({ state, commit }, {row,allMenusEms,allMenusVms,allGroups}) {
    state.roleId = row.id
    state.loading = true
    const response = await DataService.callApi(
      {url:ConstantAPI.role.GET_BY_ID.url + `/${row.id}`},
      null,
    );
    const responseListUser = await DataService.callApi(
      ConstantAPI.user.GET_LIST_USER_BY_ROLE,
      null,
      {roleId:row.id},
    );
    state.group.id = row.id
    state.action = 1;
    commit('setFormValue', {
      group:response.data, 
      allMenusEms:allMenusEms,
      allMenusVms:allMenusVms,
      allGroups:allGroups,
      allCamera:state.listTreeCamera,
      listDataUser:responseListUser.data
    });
  },
  async preView({ state, commit }, {row,allMenusEms,allMenusVms,allGroups}) {
    state.roleId = row.id
    state.loading = true
    const response = await DataService.callApi(
      {url:ConstantAPI.role.GET_BY_ID.url + `/${row.id}`},
      null,
    );
    const responseListUser = await DataService.callApi(
      ConstantAPI.user.GET_LIST_USER_BY_ROLE,
      null,
      {roleId:row.id},
    );
    state.action = 0;
    commit('setFormValue', {
      group:response.data, 
      allMenusEms:allMenusEms,
      allMenusVms:allMenusVms,
      allGroups:allGroups,
      allCamera:state.listTreeCamera,
      listDataUser:responseListUser.data
    });
  },
  resetForm({ state }) {
    state.group = null;
  },
  async create({ commit, state }, payload) {
    try {
      const { t } = i18n.global;
      commit('setLoading', true);
        const response = await DataService.callApi(state.api.CREATE, {
          ...payload,
          type: 2,
          children: (payload.children || []).map((e) => ({ id: e })),
          userGroup: (payload.userGroup || []).map((e) => ({ userId: e })),
        });
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('common.create_success', {
              name: t('user_group.u_group_tree'),
            }),
            duration: 4,
          });
        });
        return success;
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async update({ commit, state }, payload) {
    try {
      const { t } = i18n.global;
      commit('setLoading', true);
        const response = await DataService.callApi(state.api.UPDATE, {
          ...payload,
        });
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('common.update_success', {
              name: t('user_group.u_group_tree'),
            }),
            duration: 4,
          });
        });
        return success;
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async delete(_,{ id }) {
    try {
      const { t } = i18n.global;
      const response = await DataService.delete(
        ConstantAPI.role.DELETE.url + `/${id}`,
      );
      let success = false;
      checkResponse(response, () => {
        success = true;
        notification.success({
          message: t('common.notification'),
          description: t('common.delete_success', {
            name: t('user_group.u_group_tree'),
          }),
          duration: 4,
        });
      });
      return success;
    } catch (err) {
      console.log(err);
      return false;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
