import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { checkResponse } from '@/util/common-utils';
import { notification } from 'ant-design-vue';
import { showConfirm } from '@/util/confirm';
import { i18n } from '@/main';
const state = () => ({
  vehicle: {
    vehicleType: '',
    licensePlate: '',
    color: '',
    identityObjects: [],
    images: [],
    ioGroups: [],
    vehicleCards: {
      type: '',
      licensePlate: '',
      code: '',
      brandId: '',
      color: '',
      address: '',
      images: [],
    }
  },
  vehicleColors: [],
  vehicleTypes: [],
  vehicleBrands: [],
  loading: false,
  error: null,
});
const actions = {
  async vehicleGetData({ commit }, payload) {
    try {
      commit('fetchDataBegin');
      const response = await DataService.get(
        `${ConstantAPI.indentity_management.GET_VEHICLE.url}/${payload.id}`
      );
      return commit('fetchDataSuccess', response.data);
    } catch (err) {
      commit('fetchDataErr', err);
    }
  },
  async getVehicleColors({ commit }, payload) {
    try {
      const response = await DataService.callApi(
        ConstantAPI.indentity_management.GET_APP_PARAMS,
        null,
        payload
      );
      return commit('fetchDataVehicleColorsSuccess', response.data);
    } catch (err) {
      commit('fetchDataErr', err);
    }
  },
  async getVehicleBrands({ commit }, payload) {
    try {
      const response = await DataService.callApi(
        ConstantAPI.indentity_management.GET_APP_PARAMS,
        null,
        payload
      );
      return commit('fetchDataVehicleBrandsSuccess', response.data);
    } catch (err) {
      commit('fetchDataErr', err);
    }
  },
  async getVehicleTypes({ commit }, payload) {
    try {
      const response = await DataService.callApi(
        ConstantAPI.indentity_management.GET_APP_PARAMS,
        null,
        payload
      );
      return commit('fetchDataVehicleTypesSuccess', response.data);
    } catch (err) {
      commit('fetchDataErr', err);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async updateVehicle({ commit }, payload) {
    const { t } = i18n.global;
    try {
      if (await showConfirm('Are you sure you want to save?')) {
        // commit('setLoading', true);
        const response = await DataService.callApi(
          ConstantAPI.indentity_management.UPDATE_VEHICLE,
          payload,
          null
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('common.update_success'),
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      // commit('setLoading', false);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async createVehicle({ commit }, payload) {
    const { t } = i18n.global;
    try {
      if (await showConfirm('Are you sure you want to create?')) {
        // commit('setLoading', true);
        const response = await DataService.callApi(
          ConstantAPI.indentity_management.CREATE_VEHICLE,
          payload,
          null
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('common.create_success'),
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      // commit('setLoading', false);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async delete({ state }, rows) {
    const { t } = i18n.global;
    try {
      if (await showConfirm('Are you sure you want to delete?')) {
        const response = await DataService.delete(
          ConstantAPI.indentity_management.DELETE_VEHICLE.url + '/' + rows.map((e) => e.id).join(',')
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('common.delete_success'),
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
