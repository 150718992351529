import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import {DataService} from '@/dataService/dataService';
import {getField, updateField} from 'vuex-map-fields';
import {FORM_MODE} from '@/util/common-constant';
import {checkResponse} from '@/util/common-utils';
import {notification} from 'ant-design-vue';
import {i18n} from "@/main";

const state = () => ({
    list: [],
    loading: false,
    user: {},
    api: ConstantAPI.user,
    visible: false,
    organizations: [],
    departments: [],
    positions: [],
    listKeyValue: {},
});

const actions = {
  async getAll({ commit, state }) {
    try {
      const response = await DataService.callApi(state.api.GET_ALL);
      if (response.data) {
        for (let i in response.data) {
          state.listKeyValue[response.data[i].id] = response.data[i];
        }
      }
      return commit('getAllSuccess', response.data);
    } catch (err) {
      console.log(err);
    }
  },
    async loadSuggest({commit, state}) {
        try {
            const organization = await DataService.callApi(state.api.GET_SUGGEST, null, {column: 'organization'});
            const department = await DataService.callApi(state.api.GET_SUGGEST, null, {column: 'department'});
            const position = await DataService.callApi(state.api.GET_SUGGEST, null, {column: 'position'});
            return commit('setSuggest', {
                organizations: organization.data,
                departments: department.data,
                positions: position.data
            });
        } catch (err) {
            console.log(err);
        }
    },
    async preCreate({commit}) {
        commit('preCreate');
    },
    async preUpdate({commit}, row) {
        const response = await DataService.callApi(
            ConstantAPI.user_role.GET_BY_USER,
            null,
            {userId: row.id}
        );
        commit('setFormValue', {
            user: {...row, userRoles: (response.data || []).map((e) => e.roleId),},
            formMode: FORM_MODE.UPDATE,
        });
    },
    async preView({commit}, row) {
        const response = await DataService.callApi(
            ConstantAPI.user_role.GET_BY_USER,
            null,
            {userId: row.id}
        );
        commit('setFormValue', {
            user: {
                ...row,
                userRoles: (response.data || []).map((e) => e.roleId),
            },
            formMode: FORM_MODE.VIEW,
        });
    },
    async create({commit, state}, payload) {
        const { t } = i18n.global;
        try {
            commit('setLoading', true);
                let isSuccess = false
                let listUserRoles = payload.userRoles.map((item)=>({roleId:item})) || []
                const data = {
                    ...payload,
                    userRoles: listUserRoles,
                };
                const response = await DataService.callApi(
                    state.api.CREATE,
                    data,
                    null,
                );
                checkResponse(response, () => {
                    notification.success({
                        message: t('common.notification'),
                        description: t('user.add_user_success'),
                        duration: 4,
                    });
                    isSuccess = true
                    state.visible = false
                })
                return isSuccess
        } catch (err) {
            console.log(err);
        } finally {
            commit('setLoading', false);
        }
    },
    async update({commit, state}, payload) {
        try {
            const { t } = i18n.global;
            let listUserRoles = payload.userRoles.map((item)=>({roleId:item})) || []
            commit('setLoading', true);
            const data = {
                ...payload,
                userRoles: listUserRoles,
                id: state.user.id,
            };
            const response = await DataService.callApi(
                state.api.UPDATE,
                data,
                null,
            );
            checkResponse(response, () => {
                notification.success({
                    message:  t('common.notification'),
                    description: t('user.update_user_success'),
                    duration: 4,
                });
                state.visible = false
            })
        } catch (err) {
            console.log(err);
        } finally {
            commit('setLoading', false);
        }
    },
    async delete({state}, row) {
        try {
            const { t } = i18n.global;
            const response = await DataService.delete(
                state.api.DELETE.url + '/' + row
            );
            let success = false;
            checkResponse(response, () => {
                success = true;
                notification.success({
                    message:  t('common.notification'),
                    description: t('user.delete_user_success'),
                    duration: 4,
                });
            });
            return success;
        } catch (err) {
            console.log(err);
            return false;
        }
    },
    async resetDefaultPassword({state}, row) {
        try {
            const { t } = i18n.global;
            const response = await DataService.put(
                state.api.RESET_DEFAULT_PASSWORD_BY_USER.url + '/' + row
            );
            let success = false;
            checkResponse(response, () => {
                success = true;
                notification.success({
                    message:  t('common.notification'),
                    description: `${t('user.reset_password_successfully')} Abc@123`,
                    duration: 4,
                });
            });
            return success;
        } catch (err) {
            console.log(err);
            return false;
        }
    },
    async setVisible({state}, visible) {
        state.visible = visible;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations: {
        ...mutations,
        updateField,
    },
    getters: {
        getField,
    },
};
