import { flatten } from '@/util/common-utils';
import {STATUS_CHANNEL,ACTION} from "@/util/common-constant";
export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  getAllSuccess(state, data) {
    state.tree = data;
    state.listChannel = flatten(data);
    state.loading = false;
  },
  preCreate(state) {
    state.channel = {
      name: null,
      desc: null,
      status: STATUS_CHANNEL.ACTIVE,
      users: [],
      group: [],
    };
    state.action = ACTION.CREATE
    state.isAddingMember =true
  },
  setFormValue(state, data ) {
    const {row, listUser,type} = data
    if(type === 'update'){
      state.action = ACTION.EDIT
    }
    else{
      state.action = ACTION.VIEW
    }
    let arrListUser = [];
    state.visible = true;
    listUser.map((item)=>{
      arrListUser.push({
        img: item.avatar,
        email: item.email,
        name: item.userName,
        phone: item.phone,
        state: item.status.online,
        label: item.avatar,
        id: item.userId,
        value:item.userName,
      })
    })
    state.channel = {
      id:row.channelId,
      name: row.name,
      desc: row.description,
      status:row.active?STATUS_CHANNEL.ACTIVE:STATUS_CHANNEL.INACTIVE,
      users:arrListUser
    };
    state.users = arrListUser
    state.loading = false;
  },
};
