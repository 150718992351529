import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { checkResponse } from '@/util/common-utils';
import { notification } from 'ant-design-vue';
import { showConfirm } from '@/util/confirm';
import { i18n } from '@/main';
const state = () => ({
  person: {
    age: 0,
    department: '',
    email: '',
    gender: null,
    misshapen: '',
    name: '',
    org: '',
    phone: '',
    position: '',
    staffCode: '',
    images: [],
    ioGroups: [],
    personCard: {
      code: '',
      dateOfBirth: '',
      gender: null,
      nationality: '',
      type: null,
      addressOrigin: {
        wardId: null,
        districtId: null,
        provinceId: null,
        addressDetail: '',
      },
      addressPermanent: {
        wardId: null,
        districtId: null,
        provinceId: null,
        addressDetail: '',
      },
      placeOfIssue: '',
      images: [],
    },
  },
  cardTypes: [],
  suggestPositions: [],
  suggestDepartments: [],
  suggestOrganizations: [],
  loading: false,
  error: null,
  ioSelects: []
});
const actions = {
  async personGetData({ commit }, payload) {
    
    try {
      commit('fetchDataBegin');
      const response = await DataService.get(
        `${ConstantAPI.indentity_management.GET_PERSON.url}/${payload.id}`
      );
      return commit('fetchDataSuccess', response.data);
    } catch (err) {
      commit('fetchDataErr', err);
    }
  },
  async getCardTypes({ commit }, payload) {
    try {
      const response = await DataService.callApi(
        ConstantAPI.indentity_management.GET_APP_PARAMS,
        null,
        payload
      );
      return commit('fetchDataCardTypesSuccess', response.data);
    } catch (err) {
      commit('fetchDataErr', err);
    }
  },
  async getSuggestPosition({ commit }, payload) {
    try {
      const response = await DataService.callApi(
        ConstantAPI.indentity_management.GET_PERSON_SUGGEST,
        null,
        payload
      );
      return commit('fetchDataSuggestPositionSuccess', response.data);
    } catch (err) {
      commit('fetchDataErr', err);
    }
  },
  async getSuggestDepartment({ commit }, payload) {
    try {
      const response = await DataService.callApi(
        ConstantAPI.indentity_management.GET_PERSON_SUGGEST,
        null,
        payload
      );
      return commit('fetchDataSuggestDepartmentSuccess', response.data);
    } catch (err) {
      commit('fetchDataErr', err);
    }
  },
  async getSuggestOrganization({ commit }, payload) {
    try {
      const response = await DataService.callApi(
        ConstantAPI.indentity_management.GET_PERSON_SUGGEST,
        null,
        payload
      );
      return commit('fetchDataSuggestOrganizationSuccess', response.data);
    } catch (err) {
      commit('fetchDataErr', err);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async updatePerson({ commit }, payload) {
    const { t } = i18n.global;
    try {
      if (await showConfirm('Are you sure you want to save?')) {
        // commit('setLoading', true);
        const response = await DataService.callApi(
          ConstantAPI.indentity_management.UPDATE_PERSON,
          payload,
          null
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('common.update_success'),
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      // commit('setLoading', false);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async createPerson({ commit }, payload) {
    const { t } = i18n.global;
    try {
      if (await showConfirm('Are you sure you want to create?')) {
        // commit('setLoading', true);
        const response = await DataService.callApi(
          ConstantAPI.indentity_management.CREATE_PERSON,
          payload,
          null
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('common.create_success'),
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      // commit('setLoading', false);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async delete({ state }, rows) {
    const { t } = i18n.global;
    try {
      console.log(rows);
      if (await showConfirm('Are you sure you want to delete?')) {
        const response = await DataService.delete(
          ConstantAPI.indentity_management.DELETE_PERSON.url + '/' + rows.map((e) => e.id).join(',')
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('common.delete_success'),
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async getIOSelect({ state }) {
    try {
      const response = await DataService.callApi(ConstantAPI.indentity_management.GET_IO_SELECT);
      state.ioSelects = response.data || []
    } catch (err) {
      console.log(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
