import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { checkResponse } from '@/util/common-utils';
import { notification } from 'ant-design-vue';
import { i18n } from '@/main';
import { CHAT_SERVICE } from '@/config/ConstantAPI';
const state = () => ({
  loading: false,
  channel: {},
  users: [],
  channels: [],
  api: ConstantAPI.channel,
  visible: false,
  action: 0,
  listGroup: [],
  listUserInGroup: [],
  listChannel: [],
  isAddingMember: true,
});

const actions = {
  changeAction({ state }, action) {
    state.action = action;
  },
  async getListGroup({ state }) {
    try {
      const response = await DataService.callApi(
        ConstantAPI.role.GET_ALL,
        null
      );
      let arrGroup = [];
      response.data.length > 0 &&
        response.data.map((item) => {
          arrGroup.push({
            label: `${item.name}`,
            value: `${item.id}`,
          });
        });
      state.listGroup = arrGroup;
    } catch (err) {
      console.log(err);
    }
  },
  async getListUserByGroup({ state }, value) {
    try {
      let arrUser = [];
      const response = await DataService.callApi(
        ConstantAPI.user.SEARCH,
        null,
        value
      );
      response.data.content &&
        response.data.content.map((item) => {
          arrUser.push({
            id: `${item.id}`,
            label: item.avatar,
            img: item.avatar,
            name: item.username,
            email: item.email,
            phone: item.phone,
            value: item.username,
          });
        });
      state.listUserInGroup = arrUser;
      state.listUserHaveSelect = arrUser;
    } catch (err) {
      console.log(err);
    }
  },
  async getAll({ commit }) {
    try {
      const response = await DataService.get(
        `${CHAT_SERVICE}/channels/list-channels`
      );
      return commit('getAllSuccess', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async preCreate({ commit }) {
    commit('preCreate');
  },
  async preChangeAddMember({ state }, status) {
    state.isAddingMember = status;
  },
  async preUpdate({ state, commit }, row) {
    state.isAddingMember = true;
    const response = await DataService.get(
      `${state.api.GET_PARTICIPANTS.url}?channelId=${row.channelId}&size=9999`
    );
    commit('setFormValue', {
      row: row,
      listUser: response.data.content,
      type: 'update',
    });
  },
  async preView({ state, commit }, row) {
    state.isAddingMember = true;
    const response = await DataService.get(
      `${state.api.GET_PARTICIPANTS.url}?channelId=${row.channelId}&size=9999`
    );
    commit('setFormValue', {
      row: row,
      listUser: response.data.content,
      type: 'view',
    });
  },
  resetForm({ state }) {
    state.group = null;
  },
  async create({ commit, state }, payload) {
    try {
      const { t } = i18n.global;
      commit('setLoading', true);
      const response = await DataService.callApi(
        state.api.CREATE_CHANNEL,
        payload
      );
      let success = false;
      checkResponse(response, () => {
        success = true;
        notification.success({
          message: t('common.notification'),
          description: t('common.create_success', {
            name: t('channel.noti_channel'),
          }),
          duration: 4,
        });
      });
      return success;
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async update({ state, commit }, payload) {
    try {
      const { t } = i18n.global;
      commit('setLoading', true);
      const response = await DataService.callApi(
        state.api.UPDATE_CHANNEL,
        payload
      );
      let success = false;
      checkResponse(response, () => {
        success = true;
        notification.success({
          message: t('common.notification'),
          description: t('common.update_success', {
            name: t('channel.noti_channel'),
          }),
          duration: 4,
        });
      });
      return success;
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async deleteUser({ state, commit }, payload) {
    try {
      commit('setLoading', true);
      const response = await DataService.callApi(
        state.api.DELETE_USER_CHANNEL,
        payload
      );
      return response;
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async addUser({ state, commit }, payload) {
    try {
      commit('setLoading', true);
      const response = await DataService.callApi(
        state.api.ADD_USER_CHANNEL,
        payload
      );
      return response;
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async delete({ state }, payload) {
    try {
      const { t } = i18n.global;
      const response = await DataService.callApi(
        state.api.DELETE_CHANNEL,
        payload
      );
      let success = false;
      checkResponse(response, () => {
        success = true;
        notification.success({
          message: t('common.notification'),
          description: t('common.delete_success', {
            name: t('channel.noti_channel'),
          }),
          duration: 4,
        });
      });
      return success;
    } catch (err) {
      console.log(err);
      return false;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
