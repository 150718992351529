<template>
  <a-modal
      v-model:visible="isVisible"
      :title="title"
      v-bind="hideFooter ? {footer: null} : {}"
      :destroyOnClose="destroyOnClose"
      :maskClosable="maskClosable"
      :width="width"
      class="modal-custom"
      @ok="handleOk"
      :closable="closable"
      :centered="centered"
  >
    <template #closeIcon>
      <div>
        <div class="icon-close-modal mt-[18px] ml-[14px]"></div>
      </div>
    </template>
    <template #footer v-if="!hideFooter">
      <slot name="footer">
        <cds-button key="back" type="light" size="small" @click="onCancel">{{ cancelTitle }}</cds-button>
        <cds-button
            key="submit"
            type="primary"
            size="small"
            @click="handleOk"
        >{{ acceptTitle }}
        </cds-button>
      </slot>
    </template>

    <a-spin :spinning="loading">
      <slot></slot>
    </a-spin>
  </a-modal>
</template>

<script>
import {defineComponent, ref} from 'vue';
import VueTypes from "vue-types";

export default defineComponent({
  name: 'Modal',
  props: {
    visible: VueTypes.bool.def(true),
    loading: VueTypes.bool.def(false),
    title: VueTypes.string,
    destroyOnClose: VueTypes.bool.def(true),
    cancelTitle: VueTypes.string.def('Cancel'),
    acceptTitle: VueTypes.string.def('Đồng ý'),
    hideFooter: VueTypes.bool.def(false),
    maskClosable: VueTypes.bool.def(false),
    width: VueTypes.string.def('600px'),
    closable: VueTypes.bool.def(true),
    centered:VueTypes.bool.def(false),
  },
  data(props) {
    let isVisible = ref(props.visible);
    return {
      className: this.class,
      isVisible,
    };
  },
  watch: {
    visible(val) {
      this.isVisible = val;
    },
    isVisible(val) {
      if (!val) {
        this.onCancel();
      }
    }
  },
  methods: {
    handleOk() {
      this.$emit('onOk');
    },
    onCancel() {
      this.$emit('onCancel');
    }
  }
});
</script>

<style lang="scss">
.modal-custom .icon-close-modal {
  background-image: url('../../static/img/icon/cancel.svg');
  background-size: cover;
  height: 24px;
  width: 24px;
}
.modal-custom .icon-close-modal:hover {
  background-image: url('../../static/img/icon/cancel-active.svg');
}
.modal-custom .ant-modal-header {
  padding: 16px 24px;
  border-bottom: 1px solid #434343;
  --tw-bg-opacity: 0.8;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(217, 217, 217, var(--tw-text-opacity));
}
.modal-custom .ant-modal-title {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.75rem;
  line-height: 1.5;
  --tw-text-opacity: 1;
  color: rgba(217, 217, 217, var(--tw-text-opacity));
  overflow-wrap: break-word;
}
.modal-custom .ant-modal-body {
  padding: 24px;
  --tw-bg-opacity: 1;
  background-color: rgba(38, 38, 38, var(--tw-bg-opacity));
  font-size: 1rem;
  line-height: 1.5rem;
  line-height: 1.5;
  --tw-text-opacity: 1;
  color: rgba(217, 217, 217, var(--tw-text-opacity));
  overflow-wrap: break-word;
}
.modal-custom .ant-form-item-label > label {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 36rem;
  max-width: 100%;
  position: relative;
}
</style>
