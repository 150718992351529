import { flatten,trim } from '@/util/common-utils';
const getViolation = (state) => {
  let data = [];
  state.formState.violation.map((el) => {
    if (
      el.charge !== '' &&
      el.decisionNoDate !== '' &&
      el.decisionUnit !== ''
    ) {
      data.push(el);
    }
  });
  return data;
};
export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setObjectName(state, data) {
    state.objectName = data;
  },
  fetchDataSuccess(state, data) {
    state.loading = false;
    state.treeGroup = data;
    state.groups = flatten(data);
    state.loading = false;
  },
  fetchDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },
  setFormHuman(state) {
    state.formHuman.name = trim(state.formState.name);
    state.formHuman.characteristic = trim(state.formState.characteristic);
    state.formHuman.imagesAvatar = state.formState.imagesAvatar;
    state.formHuman.imagesRelate = state.formState.imagesRelate;
    state.formHuman.imagesRelateAdded = state.formState.imagesRelateAdded;
    state.formHuman.imagesRelateDeleted = state.formState.imagesRelateDeleted;
    state.formHuman.violation = getViolation(state);
    state.formHuman.ioGroups = state.formState.ioGroups.map((item) => {
      return { groupId: item.id, type: state.formState.objectType };
    });
  },
  setFormVehicle(state) {
    state.formVehicle.name = trim(state.formState.name);
    state.formVehicle.characteristic = trim(state.formState.characteristic);
    state.formVehicle.imagesAvatar = state.formState.imagesAvatar;
    state.formVehicle.imagesRelate = state.formState.imagesRelate;
    state.formVehicle.imagesRelateAdded = state.formState.imagesRelateAdded;
    state.formVehicle.imagesRelateDeleted = state.formState.imagesRelateDeleted;
    state.formVehicle.violation = getViolation(state);
    state.formVehicle.ioGroups = state.formState.ioGroups.map((item) => {
      return { groupId: item.id, type: state.formState.objectType };
    });
  },
  setFormSymbol(state) {
    state.formSymbol.name = trim(state.formState.name);
    state.formSymbol.characteristic = trim(state.formState.characteristic);
    state.formSymbol.imagesAvatar = state.formState.imagesAvatar;
    state.formSymbol.imagesRelate = state.formState.imagesRelate;
    state.formSymbol.imagesRelateAdded = state.formState.imagesRelateAdded;
    state.formSymbol.imagesRelateDeleted = state.formState.imagesRelateDeleted;
    state.formSymbol.violation = getViolation(state);
    state.formSymbol.ioGroups = state.formState.ioGroups.map((item) => {
      return { groupId: item.id, type: state.formState.objectType };
    });
  },
  setFormLocation(state) {
    state.formLocation.name = trim(state.formState.name);
    state.formLocation.characteristic = trim(state.formState.characteristic);
    state.formLocation.imagesAvatar = state.formState.imagesAvatar;
    state.formLocation.imagesRelate = state.formState.imagesRelate;
    state.formLocation.imagesRelateAdded = state.formState.imagesRelateAdded;
    state.formLocation.imagesRelateDeleted =
      state.formState.imagesRelateDeleted;
    state.formLocation.violation = getViolation(state);
    state.formLocation.ioGroups = state.formState.ioGroups.map((item) => {
      return { groupId: item.id, type: state.formState.objectType };
    });
  },
  resetForm(state) {
    state.listTree = [];
    state.tabGroups = [];
    state.activeTab = '';
    state.currentTree = {};
    state.listMergeProfile = [];
    state.formSymbol = {
      name: '',
      characteristic: '',
      color: '',
      id: null,
      imagesAvatar: [],
      imagesRelate: [],
      imagesRelateAdded: [],
      imagesRelateDeleted: [],
      ioGroups: [],
      isDeleted: false,
      note: '',
      ownerAddress: '',
      ownerName: '',
      ownerIdCard: '',
    };
    state.formHuman = {
      address: '',
      country: undefined,
      dateOfBirth: null,
      dateOfIssue: null,
      districtId: undefined,
      email: '',
      exhibitDetail: '',
      fatherExhibit: '',
      fatherName: '',
      gender: undefined,
      identification: '',
      imagesAvatar: [],
      imagesRelate: [],
      imagesRelateAdded: [],
      imagesRelateDeleted: [],
      insuranceCode: '',
      ioGroups: [],
      isDeleted: undefined,
      job: '',
      motherExhibit: '',
      motherName: '',
      spouseExhibit: '',
      spouseName: '',
      name: '',
      characteristic: '',
      nationality: undefined,
      note: '',
      phone: '',
      placeOfIssue: '',
      provinceId: undefined,
      realName: '',
      socialNetwork: '',
      taxCode: '',
      typeExhibit: undefined,
      wardId: undefined,
    };
    state.formLocation = {
      address: null,
      characteristic: null,
      contactAddress: null,
      contactName: null,
      contactPhone: null,
      districtId: null,
      id: null,
      imagesAvatar: [],
      imagesRelate: [],
      imagesRelateAdded: [],
      imagesRelateDeleted: [],
      ioGroups: [],
      isDeleted: true,
      name: null,
      note: null,
      ownerAddress: null,
      ownerIdCard: null,
      ownerName: null,
      provinceId: null,
      type: null,
      wardId: null,
    };
    state.formVehicle = {
      brandId: '',
      characteristic: '',
      chassisNumber: '',
      engineNumber: '',
      id: null,
      imagesAvatar: [],
      imagesRelate: [],
      imagesRelateAdded: [],
      imagesRelateDeleted: [],
      ioGroups: [],
      isDeleted: false,
      licensePlate: '',
      licensePlateType: '',
      manufacturingDate: null,
      name: '',
      note: '',
      ownerAddress: '',
      ownerIdCard: '',
      ownerVehicle: '',
      registrationAddress: '',
      registrationDate: null,
      registrationNo: '',
      vehicleColor: '',
      vehicleType: '',
    };
    state.formState = {
      violation: [],
      objectType: undefined,
      name: '',
      characteristic: '',
      imagesAvatar: [],
      imagesRelate: [],
      ioGroups: [],
      imagesRelateAdded: [],
      imagesRelateDeleted: [],
    };
  },
};
