<template>
  <div class="align-center action-button">
    <div class="action-button-custom flex">
      <a-tooltip
        :title="$t('object_tracing.change_status')"
        v-if="button.hasChangeStatus"
      >
        <a-button
          type="ghost"
          class="mr-5 text-ems-gray500"
          @click="emitEvent('onChangeStatus')"
        >
          <template #icon>
            <img
              v-if="item.status"
              class="w-20px h-20px m-auto"
              src="@/static/img/icon/PersonSearchActiveIcon.svg"
              alt="PersonSearchActiveIcon"
            />
            <img
              v-else
              class="w-20px h-20px m-auto"
              src="@/static/img/icon/PersonSearchIcon.svg"
              alt="PersonSearchIcon"
            />
          </template>
        </a-button>
      </a-tooltip>
      <a-tooltip :title="$t('object_tracing.warning')" v-if="button.hasWarning">
        <a-button
          type="ghost"
          class="mr-5 text-ems-gray500"
          @click="emitEvent('onWarning')"
        >
          <template #icon>
            <img
              class="w-20px h-20px m-auto"
              src="@/static/img/icon/WarningIconGray.svg"
              alt="WarningIconGray"
            />
          </template>
        </a-button>
      </a-tooltip>
      <a-tooltip :title="$t('common.view')" v-if="button.hasView">
        <a-button
          type="ghost"
          class="mr-5 text-ems-gray500"
          @click="emitEvent('onView')"
        >
          <template #icon>
            <cds-feather-icons type="eye" />
          </template>
        </a-button>
      </a-tooltip>
      <a-tooltip :title="$t('common.edit')" v-if="button.hasEdit">
        <a-button
          type="ghost"
          :disabled="disabledEdit"
          class="mr-5 text-ems-gray500"
          @click="emitEvent('onEdit')"
        >
          <template #icon>
            <cds-feather-icons type="edit" />
          </template>
        </a-button>
      </a-tooltip>
      <a-tooltip :title="$t('common.delete')" v-if="button.hasDelete">
        <a-button
          type="ghost"
          @click="emitEvent('onDelete')"
          class="text-ems-gray500"
        >
          <template #icon>
            <cds-feather-icons type="trash" />
          </template>
        </a-button>
      </a-tooltip>
    </div>
    <div class="my-2">
      <a-tooltip
        :title="$t('common.Export private key')"
        v-if="button.hasExport"
      >
        <a-button
          type="ghost"
          @click="emitEvent('onExport')"
          class="mr-5 text-ems-gray500"
        >
          <template #icon>
            <cds-feather-icons type="upload" />
          </template>
        </a-button>
      </a-tooltip>
      <a-tooltip :title="$t('common.Activity')" v-if="button.hasActivity">
        <a-button
          type="ghost"
          @click="emitEvent('onActivity')"
          class="text-ems-gray500"
        >
          <template #icon>
            <cds-feather-icons type="activity" />
          </template>
        </a-button>
      </a-tooltip>
    </div>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import { computed, defineComponent, ref, watch } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'ActionButton',
  props: {
    // {type, permission}
    buttons: VueTypes.array.def([]),
    disabledEdit: VueTypes.bool.def(false),
    isShowApproval: VueTypes.bool.def(false),
    item: VueTypes.object.def({}),
  },
  setup(props, { emit }) {
    const emitEvent = (event) => {
      emit(event);
    };
    const { state } = useStore();
    const permissions = computed(() => state.auth.userInfo.permissions || []);
    const hasButton = (button) =>
      props.buttons.some(
        (e) =>
          e.type === button &&
          (!e.permission || permissions.value.includes(e.permission))
      );
    const button = ref({
      hasEdit: hasButton('EDIT'),
      hasView: hasButton('VIEW'),
      hasDelete: hasButton('DELETE'),
      hasExport: hasButton('EXPORT'),
      hasActivity: hasButton('ACTIVITY'),
      hasWarning: hasButton('WARNING'),
      hasChangeStatus: hasButton('CHANGE_STATUS'),
    });
    watch(
      () => permissions.value,
      (val) => {
        button.value.hasEdit = hasButton('EDIT');
        button.value.hasView = hasButton('VIEW');
        button.value.hasDelete = hasButton('DELETE');
        button.value.hasExport = hasButton('EXPORT');
        button.value.hasActivity = hasButton('ACTIVITY');
        button.value.hasWarning = hasButton('WARNING');
        button.value.hasChangeStatus = hasButton('CHANGE_STATUS');
      }
    );
    return {
      emitEvent,
      button,
    };
  },
});
</script>

<style lang="scss" scoped>

.action-button {
  .ant-btn {
    border: none;
  }
}

</style>
