export default {
    setLoading(state, loading) {
        state.loading = loading
    },
    setDataChart(state, data) {
        state.dataChart.key = data.map(e => e.key)
        state.dataChart.dataIn = data.map(e => e.totalIn)
        state.dataChart.dataOut = data.map(e => e.totalOut)
    }
};
